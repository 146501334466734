import React, { FC, KeyboardEvent, MouseEvent } from 'react';

import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Link, { LinkProps } from 'next/link';

export interface IMenuListItem {
  href: LinkProps['href'];
  direction: 'vertical' | 'horizontal';
  label: string;
  selected?: boolean;
  onClick: (event: KeyboardEvent | MouseEvent) => void;
}

const MenuListItem: FC<IMenuListItem> = (props) => {
  const { href, direction, label, selected, onClick } = props;

  const isVertical = direction === 'vertical';

  return (
    <li>
      <Link href={href} scroll={false} shallow passHref>
        <ListItem onClick={onClick} selected={selected} component="a" button>
          {isVertical && (
            <ListItemIcon>
              <ChevronRightIcon />
            </ListItemIcon>
          )}
          <ListItemText primary={label} />
        </ListItem>
      </Link>
    </li>
  );
};

export default MenuListItem;
