import Cookie from 'js-cookie';

export enum COOKIES {
  Jwt = 'jwt',
  Cart = 'cart'
}

export function getCookie(key: COOKIES): string {
  return Cookie.get(key);
}

export function setCookie(key: COOKIES, value: unknown): void {
  return Cookie.set(key, value);
}

export function removeCookie(key: COOKIES): void {
  return Cookie.remove(key);
}
