import React, { FC, FocusEvent, useState } from 'react';

import { cep } from 'cep-promise';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';

import Dropdown from '@components/form/Dropdown';
import Input from '@components/form/Input';
import { deliveryFees } from '@helpers/checkout';
// import { canDeliver } from '@services/delivery-fee';

const AddressForm: FC = () => {
  const formik = useFormikContext();

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  async function getAddressByZipCode(event: FocusEvent<HTMLInputElement>) {
    setLoading(true);

    try {
      const zipCode = event.target.value;

      const cepResponse = await cep(zipCode, {});
      // const canDeliverResponse = await canDeliver(cepResponse);

      // if (!canDeliverResponse.data.deliver) {
      //   throw Error(
      //     'Infelizmente não entregamos no seu endereço, por favor, tente um endereço diferente'
      //   );
      // }

      formik.setFieldValue('street', cepResponse.street);
      formik.setFieldValue('neighborhood', cepResponse.neighborhood);
      formik.setFieldValue('city', cepResponse.city);
      formik.setFieldValue('state', cepResponse.state);
    } catch (error) {
      formik.setFieldValue('street', '');
      formik.setFieldValue('neighborhood', '');
      formik.setFieldValue('city', '');
      formik.setFieldValue('state', '');

      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Input
        name="zip_code"
        id="zip_code"
        label="CEP"
        onBlur={getAddressByZipCode}
        mask="99999-999"
        placeholder="____-___"
      />
      <Input name="street" id="street" label="Rua" disabled={loading} />
      <Input name="house_number" id="house_number" label="Número" disabled={loading} />
      <Input
        name="house_reference"
        id="house_number"
        label="Complemento"
        placeholder="Apartamento, andar, etc."
        disabled={loading}
      />
      <Input name="neighborhood" id="neighborhood" label="Bairro" disabled={loading} />
      <Dropdown
        name="city"
        id="city"
        label="Cidade"
        options={Object.keys(deliveryFees).map((key) => ({ value: key, label: key }))}
        disabled={loading}
      />
      {/* <Input name="state" id="state" label="Estado" /> */}
    </>
  );
};
export default AddressForm;
