import * as Sentry from '@sentry/browser';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { API_URL } from '@config/constants';
import { COOKIES, getCookie } from '@services/cookies';

export type ApiResponse<T> = Promise<AxiosResponse<T>>;

const onRequest = async (config) => {
  const token = getCookie(COOKIES.Jwt);

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

// ----- //

const onResponse = (response) => response;

const onResponseError = (error: AxiosError) => {
  const isWrongCredentials = error.config.url === '/auth/local/' && error.response.status === 400;

  if (!isWrongCredentials) {
    Sentry.captureException(error, {
      tags: { url: error.config.url },
      extra: {
        requestData: JSON.stringify(error.config.data),
        responseData: JSON.stringify(error.response.data)
      }
    });
  }

  return Promise.reject(error);
};

// ----- //

export const api = axios.create({
  baseURL: `${API_URL}`
});

api.interceptors.request.use(onRequest, onRequestError);
api.interceptors.response.use(onResponse, onResponseError);
