import React, { FC, useContext, useState } from 'react';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import CloseIcon from '@material-ui/icons/Close';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PersonIcon from '@material-ui/icons/Person';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';

import Button from '@components/shared/Button';
import analytics from '@config/analytics';
import { registerUser } from '@services/auth';
// import { canDeliver } from '@services/delivery-fee';

import { UIContext } from '../../context/UIContext';
import { UserContext } from '../../context/UserContext';
import AddressForm from './AddressForm';
import CredentialsForm from './CredentialsForm';
import { REGISTER_INITIAL_VALUES, RegisterValidationSchema } from './helpers';
import { IRegisterForm } from './interfaces';
import Stepper from './Stepper';
import UserForm from './UserForm';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem 2rem'
  },
  padding: {
    padding: '1rem 2rem'
  },
  actionsContainer: {
    marginTop: '1rem',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  alignRight: {
    marginLeft: 'auto'
  }
});

const Register: FC = () => {
  const classes = useStyles();

  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const fullScreen = useMediaQuery('(max-width:400px)');

  const { enqueueSnackbar } = useSnackbar();
  const userContext = useContext(UserContext);
  const uiContext = useContext(UIContext);

  const [loading, setLoading] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const isFirstStep = activeStep === 0;
  const isLastStep = activeStep === getSteps().length - 1;

  function handleClose() {
    uiContext.register.toggleModal(false);
  }

  function handleCloseRegisterModal() {
    handleClose();

    analytics.event('Fechar', {
      event_category: 'Modal Cadastro',
      event_label: 'Botão Fechar'
    });
  }

  function handleBack() {
    const step = activeStep - 1;
    setActiveStep(step);

    analytics.event('Cadastrar', {
      event_category: 'Modal Cadastro',
      event_label: 'Botão Voltar',
      value: step
    });
  }

  function handleNext() {
    const step = activeStep + 1;
    setActiveStep(step);

    analytics.event('Cadastrar', {
      event_category: 'Modal Cadastro',
      event_label: 'Botão Continuar',
      value: step
    });
  }

  function getSteps() {
    return [
      {
        label: 'Credenciais',
        icon: <AlternateEmailIcon />,
        component: <CredentialsForm />
      },
      {
        label: 'Informações Pessoais',
        icon: <PersonIcon />,
        component: <UserForm />
      },
      {
        label: 'Endereço de entrega',
        icon: <LocalShippingIcon />,
        component: <AddressForm />
      }
    ];
  }

  async function handleSubmit(values: IRegisterForm, actions: FormikHelpers<IRegisterForm>) {
    if (isLastStep) {
      analytics.event('Cadastrar', {
        event_category: 'Modal Cadastro',
        event_label: 'Botão Enviar'
      });

      setLoading(true);

      registerUser(values)
        .then((res) => {
          userContext.setUser(res.data.user);
          uiContext.register.toggleModal(false);
          enqueueSnackbar(`Cadastro realizado com sucesso`, { variant: 'success' });
        })
        .catch((error) => {
          const errorId = error?.response?.data?.message[0]?.messages?.[0]?.id;
          const message =
            errorId === 'Auth.form.error.email.taken'
              ? 'E-mail já cadastrado'
              : 'Algo de errado aconteceu';
          enqueueSnackbar(message, { variant: 'error' });
        })
        .finally(() => setLoading(false));
    } else {
      actions.setTouched({});
      actions.setSubmitting(false);
      handleNext();
    }
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={uiContext.register.registerModalIsOpen}
      onClose={handleClose}>
      <Formik
        initialValues={REGISTER_INITIAL_VALUES}
        validationSchema={RegisterValidationSchema[activeStep]}
        onSubmit={handleSubmit}>
        {(formik: FormikProps<IRegisterForm>) => {
          return (
            <Form>
              <Box className={classes.headerContainer}>
                <DialogTitle style={{ padding: 0 }}>Cadastro</DialogTitle>
                <IconButton onClick={handleCloseRegisterModal} size="small">
                  <CloseIcon />
                </IconButton>
              </Box>
              <DialogContent className={classes.padding}>
                <DialogContentText>
                  Preencha os dados para que possamos fazer as entregas para você!
                </DialogContentText>

                <Stepper steps={getSteps()} activeStep={activeStep} />
              </DialogContent>

              <DialogActions className={`${classes.actionsContainer} ${classes.padding}`}>
                {!isFirstStep && (
                  <Button type="button" onClick={handleBack} color="primary">
                    Voltar
                  </Button>
                )}

                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.alignRight}
                  loading={loading}
                  disabled={!(formik.isValid && formik.dirty) || loading}>
                  {isLastStep ? 'Enviar' : 'Continuar'}
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default Register;
