import React, { FC, MouseEvent, useState } from 'react';

import { Box, Button, Divider, Hidden, Menu, MenuItem, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import analytics from '@config/analytics';
import { ADMIN_PATHS, USER_PATHS } from '@config/paths';
import { isSuperAdmin } from '@config/utils';
import { UserInput } from '@graphql/generated';

import MenuItemLink from '../MenuItemLink';
// import AccountCircle from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    username: {
      textTransform: 'capitalize',
      marginRight: '0.5rem'
    },
    divider: {
      margin: '3px 0'
    }
  })
);

interface IProps {
  user: UserInput;
  logout: () => void;
}

const UserMenu: FC<IProps> = (props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  function handleMenu(event: MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function collectMetrics(slug: string) {
    handleClose();

    analytics.event('Click', {
      event_category: 'Menu Perfil',
      event_label: slug
    });
  }

  return (
    <Box>
      {/* <IconButton onClick={handleMenu}>
        <AccountCircle />
      </IconButton> */}

      <Hidden mdDown>
        <Button onClick={handleMenu} variant="text">
          <span className={classes.username}>{props.user.name}</span>
          <ExpandMoreIcon fontSize="small" />
        </Button>
      </Hidden>

      <Menu
        open={open}
        keepMounted
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}>
        {/* <MenuItem disabled className={classes.username}>
          Olá {props.user.name}
        </MenuItem>
        <Divider className={classes.divider} /> */}

        {isSuperAdmin(props.user) &&
          ADMIN_PATHS.map((path) => (
            <MenuItemLink key={path.value} path={path} onClick={() => collectMetrics(path.value)} />
          ))}
        <Divider />

        {USER_PATHS.map((path) => (
          <MenuItemLink key={path.value} path={path} onClick={() => collectMetrics(path.value)} />
        ))}
        <Divider />

        <MenuItem onClick={props.logout}>Sair</MenuItem>
      </Menu>
    </Box>
  );
};

export default UserMenu;
