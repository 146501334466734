import React, { FC, useEffect, useState } from 'react';

import { Button, ButtonGroup, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { Enum_Orderitem_Unit_Type, Product } from '@graphql/generated';
import { EProductOperation, getQuantity, getUnitTypeLabel } from '@helpers/products';

interface IProps {
  product: Product;
  orientation?: 'horizontal' | 'vertical';
  value?: number;
  onChange: (quantity: number, operation: EProductOperation) => void;
  unitType: Enum_Orderitem_Unit_Type;
}

const useStyles = makeStyles<Theme, { orientation: IProps['orientation'] }>((theme) => ({
  root: (props) => ({
    borderRadius: '4px',
    width: props.orientation === 'horizontal' ? '100%' : '32px',
    height: props.orientation === 'horizontal' ? '32px' : '120px'
  }),
  subButton: {
    borderRadius: '4px',
    border: '0 !important',
    padding: 0,
    height: '100%',
    width: '100%',
    minWidth: 'auto'
  },
  counterLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'default',
    border: '0 !important',
    padding: 0,

    width: '100%',
    height: '100%',
    minWidth: 'auto',

    textTransform: 'lowercase',

    background: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.common.white} !important`
  },
  addButton: {
    borderRadius: '4px',
    border: '0 !important',
    padding: 0,
    height: '100%',
    width: '100%',
    minWidth: 'auto'
  }
}));

const ItemCounter: FC<IProps> = (props) => {
  const { product, orientation = 'horizontal', value = 0, onChange, unitType } = props;
  const classes = useStyles({ orientation });

  const [counter, setCounter] = useState(value);

  useEffect(() => {
    setCounter(value);
  }, [value]);

  const incrementor = getQuantity(product, unitType);

  function handleIncrement() {
    setCounter((prevCounter) => {
      let next = prevCounter + incrementor;
      next = Number(next.toFixed(3));
      onChange(next, EProductOperation.ADD);

      return next;
    });
  }

  function handleDecrement() {
    setCounter((prevCounter) => {
      let next = prevCounter - incrementor;
      next = Number(next.toFixed(3));
      onChange(next, EProductOperation.REMOVE);

      return next;
    });
  }

  const addButton = (
    <Button onClick={handleIncrement} className={classes.addButton}>
      <AddIcon style={{ width: 18, height: 18 }} />
    </Button>
  );

  const subButton = (
    <Button onClick={handleDecrement} className={classes.subButton}>
      <RemoveIcon style={{ width: 18, height: 18 }} />
    </Button>
  );

  const firstButton = orientation === 'horizontal' ? subButton : addButton;
  const secondButton = orientation === 'horizontal' ? addButton : subButton;

  return (
    <ButtonGroup
      size="small"
      color="primary"
      variant="contained"
      orientation={orientation}
      className={classes.root}>
      {firstButton}
      <Button
        tabIndex="-1"
        disableElevation
        disableFocusRipple
        disableRipple
        className={classes.counterLabel}>
        <span>{counter}</span>
        <span>{getUnitTypeLabel(unitType)}</span>
      </Button>
      {secondButton}
    </ButtonGroup>
  );
};

export default ItemCounter;
