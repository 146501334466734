import Router from 'next/router';

import { api, ApiResponse } from '@config/axios';
import { PATHS } from '@config/paths';
import { isSSR } from '@config/utils';
import { UserInput } from '@graphql/generated';

import { COOKIES, getCookie, removeCookie, setCookie } from '../cookies';

export async function login(
  identifier: string,
  password: string
): ApiResponse<{ jwt: string; user: UserInput }> {
  if (isSSR()) {
    return;
  }

  return api.post(`/auth/local/`, { identifier: identifier.trim(), password }).then((res) => {
    //set token response from Strapi for server validation
    setCookie(COOKIES.Jwt, res.data.jwt);

    return res;
  });
}

export async function getUser(): ApiResponse<UserInput> {
  const jwt = getCookie(COOKIES.Jwt);

  if (!jwt) {
    return null;
  }

  return api.get(`/users/me`).catch(() => {
    logout();
    return null;
  });
}

export function logout(): void {
  removeCookie(COOKIES.Jwt);
  Router.push(PATHS.ROOT, undefined, { shallow: true });
}
