import React, { FC } from 'react';

import Dropdown from '@components/form//Dropdown';
import Input from '@components/form/Input';
import { Enum_Userspermissionsuser_Gender } from '@graphql/generated';

const UserForm: FC = () => {
  return (
    <>
      <Input name="name" id="name" label="Nome" />
      <Input name="surname" id="surname" label="Sobrenome" />
      <Input
        name="phone_number"
        id="phone_number"
        label="Telefone"
        mask="(99) 99999-9999"
        placeholder="(__) _____-____"
      />
      <Input
        name="birth_date"
        id="birth_date"
        label="Data de nascimento"
        mask="99/99/9999"
        placeholder="__/__/____"
      />

      <Dropdown
        name="gender"
        id="gender"
        label="Gênero"
        options={[
          { value: Enum_Userspermissionsuser_Gender.Male, label: 'Masculino' },
          { value: Enum_Userspermissionsuser_Gender.Female, label: 'Feminino' },
          { value: Enum_Userspermissionsuser_Gender.Other, label: 'Outro' }
        ]}
      />
    </>
  );
};
export default UserForm;
