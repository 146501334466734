import React, { FC, KeyboardEvent, MouseEvent, useContext } from 'react';

import MenuList from '@components/layout/MenuDrawer/MenuList';
import MenuListItem from '@components/layout/MenuDrawer/MenuListItem';
import analytics from '@config/analytics';
import { PATHS } from '@config/paths';
import { ProductCategoryContext } from '@context/ProductCategoryContext';
import { ProductCategory } from '@graphql/generated';

interface IProps {
  isDrawer?: boolean;
  direction: 'vertical' | 'horizontal';
  onClick?: (event: KeyboardEvent | MouseEvent) => void;
}

const CategoriesMenu: FC<IProps> = (props) => {
  const { onClick, isDrawer, direction } = props;

  const productsContext = useContext(ProductCategoryContext);

  const selectedCategory = productsContext.productCategories.selectedCategory;
  const backendCategories = productsContext.productCategories.data || [];
  // const productCategories = [OFFERS_CATEGORY, ...backendCategories];
  const productCategories = backendCategories;

  function handleClick(category: ProductCategory) {
    return (event: KeyboardEvent | MouseEvent) => {
      const option = isDrawer ? 'Menu Hamburguer' : direction;
      analytics.event(`Abrir Categoria - ${option}`, {
        event_category: 'Menu Categorias',
        event_label: category.slug
      });

      productsContext.productCategories.setSelectedCategory(category.slug);

      // if (uiContext.search.query) {
      //   uiContext.search.setQuery('');
      // }

      onClick?.(event);
    };
  }

  return (
    <MenuList
      isDrawer={isDrawer}
      direction={direction}
      items={productCategories.map((category) => (
        <MenuListItem
          key={category.id}
          href={{ pathname: PATHS.ROOT, query: `category=${category.slug}` }}
          onClick={handleClick(category)}
          selected={category.slug === selectedCategory}
          label={category.name}
          direction={direction}
        />
      ))}
    />
  );
};

export default CategoriesMenu;
