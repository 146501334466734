import React, { FC, useContext, useState } from 'react';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  useMediaQuery,
  Link as MuiLink
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Formik, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';

import Input from '@components/form/Input';
import InputPassword from '@components/form/InputPassword';
import Button from '@components/shared/Button';
import analytics from '@config/analytics';
import { login } from '@services/authentication';

import { UIContext } from '../../context/UIContext';
import { UserContext } from '../../context/UserContext';
import { LOGIN_INITIAL_VALUES, LoginValidationSchema } from './helpers';
import { ILoginForm } from './interfaces';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem 2rem'
  },
  padding: {
    padding: '1rem 2rem'
  },
  actionsContainer: {
    marginTop: '1rem',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  }
});

const Login: FC = () => {
  const classes = useStyles();

  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const fullScreen = useMediaQuery('(max-width:400px)');

  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const userContext = useContext(UserContext);
  const uiContext = useContext(UIContext);

  function handleRegister() {
    uiContext.login.toggleModal(false);
    uiContext.register.toggleModal(true);

    analytics.event('Cadastrar', {
      event_category: 'Modal Login'
    });
  }

  function handleForgotPassword() {
    uiContext.login.toggleModal(false);
    uiContext.forgotPassword.toggleModal(true);

    analytics.event('Esqueci minha senha', {
      event_category: 'Modal Login'
    });
  }

  function handleClose() {
    uiContext.login.toggleModal(false);

    analytics.event('Fechar', {
      event_category: 'Modal Login',
      event_label: 'Botão Fechar'
    });
  }

  return (
    <Dialog fullScreen={fullScreen} open={uiContext.login.loginModalIsOpen} onClose={handleClose}>
      <Formik
        initialValues={LOGIN_INITIAL_VALUES}
        validationSchema={LoginValidationSchema}
        onSubmit={(values) => {
          setLoading(true);

          analytics.event('Login', {
            event_category: 'Modal Login',
            event_label: 'Botão Entrar'
          });

          login(values.email, values.password)
            .then((res) => {
              const user = res.data.user;

              userContext.setUser(user);
              uiContext.login.toggleModal(false);
              enqueueSnackbar(`Olá ${user.name}`, { variant: 'success' });
            })
            .catch(() => {
              enqueueSnackbar('E-mail ou senha incorreto', { variant: 'error' });
            })
            .finally(() => setLoading(false));
        }}>
        {(formik: FormikProps<ILoginForm>) => (
          <Form>
            <Box className={classes.headerContainer}>
              <DialogTitle style={{ padding: 0 }}>Seja bem-vindo!</DialogTitle>
              <IconButton onClick={handleClose} size="small">
                <CloseIcon />
              </IconButton>
            </Box>

            <DialogContent className={classes.padding}>
              <DialogContentText>Insira seu e-mail e senha para continuar</DialogContentText>

              <Input name="email" id="email" label="E-mail" />
              <InputPassword name="password" id="password" label="Senha" />

              <MuiLink
                color="textPrimary"
                onClick={handleForgotPassword}
                component="button"
                type="button">
                Esqueceu sua senha?
              </MuiLink>
            </DialogContent>

            <DialogActions className={`${classes.actionsContainer} ${classes.padding}`}>
              <Button size="small" color="primary" onClick={handleRegister}>
                Quero me cadastrar
              </Button>

              <Button
                type="submit"
                color="primary"
                variant="contained"
                size="large"
                loading={loading}
                disabled={!(formik.isValid && formik.dirty) || loading}>
                Entrar
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default Login;
