import React, { FC } from 'react';

import { Box, Fab, Theme, Tooltip } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import analytics from '@config/analytics';
import { PHONE_NUMBER } from '@config/constants';
import { getWhatsappMessageURL } from '@helpers/misc';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: '2rem',
      right: '2rem',

      [theme.breakpoints.down('md')]: {
        bottom: '1rem',
        right: '1rem'
      }
    }
  })
);

const WhatsAppFAB: FC = () => {
  const classes = useStyles();

  function collectMetrics() {
    analytics.event('WhatsApp', {
      event_category: 'Contato'
    });
  }

  return (
    <Box className={classes.root}>
      <Tooltip title="Ajuda">
        <Fab
          size="large"
          color="primary"
          href={getWhatsappMessageURL('Olá, poderia me ajudar?', PHONE_NUMBER)}
          target="_blank"
          rel="noopener noreferrer"
          onClick={collectMetrics}>
          <WhatsAppIcon />
        </Fab>
      </Tooltip>
    </Box>
  );
};

export default WhatsAppFAB;
