import React, { FC, memo } from 'react';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps
} from '@material-ui/core/';
import { useField } from 'formik';

interface IProps extends SelectProps {
  options: { value: number | string; label: string }[];
}

const Dropdown: FC<IProps> = (props) => {
  const [field, meta] = useField(props.name);

  return (
    <FormControl error={!!meta.error} margin="dense" fullWidth>
      <InputLabel htmlFor={field.name}>{props.label}</InputLabel>
      <Select
        {...field}
        error={!!meta.error}
        disabled={props.disabled}
        inputProps={{ id: field.name, name: field.name }}>
        {props.options?.map((option) => (
          <MenuItem key={option.value || option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {meta.touched && meta.error && (
        <FormHelperText>{(meta.touched && meta.error) || ''}</FormHelperText>
      )}
    </FormControl>
  );
};

export default memo(Dropdown);
