import React, { FC, useState, createContext } from 'react';

import { ProductCategory } from '@graphql/generated';

export type IProductCategoryContext = {
  productCategories: {
    data: ProductCategory[];
    setProductCategories: (
      productCategories: IProductCategoryContext['productCategories']['data']
    ) => void;

    selectedCategory: ProductCategory['slug'];
    setSelectedCategory: (
      selectedCategory: IProductCategoryContext['productCategories']['selectedCategory']
    ) => void;
  };
};

export const PRODUCT_CATEGORY_CONTEXT_INITIAL_VALUES: IProductCategoryContext = {
  productCategories: {
    data: [],
    setProductCategories: undefined,

    selectedCategory: null,
    setSelectedCategory: undefined
  }
};

export const ProductCategoryContext = createContext<IProductCategoryContext>(
  PRODUCT_CATEGORY_CONTEXT_INITIAL_VALUES
);

const ProductCategoryContextProvider: FC = ({ children }) => {
  const [productCategoriesData, setProductCategoriesData] = useState<
    IProductCategoryContext['productCategories']['data']
  >(PRODUCT_CATEGORY_CONTEXT_INITIAL_VALUES.productCategories.data);

  const [selectedCategory, setSelectedCategory] = useState<
    IProductCategoryContext['productCategories']['selectedCategory']
  >(PRODUCT_CATEGORY_CONTEXT_INITIAL_VALUES.productCategories.selectedCategory);

  const productsContext: IProductCategoryContext = {
    productCategories: {
      data: productCategoriesData,
      setProductCategories: setProductCategoriesData,

      selectedCategory,
      setSelectedCategory
    }
  };

  return (
    <ProductCategoryContext.Provider value={productsContext}>
      {children}
    </ProductCategoryContext.Provider>
  );
};

export default ProductCategoryContextProvider;
