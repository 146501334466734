import React, { FC, ReactNode } from 'react';

import {
  Box,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper as MuiStepper,
  Theme
} from '@material-ui/core';
import { makeStyles, withStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
    }
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1
  }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  },

  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)'
  }
});

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}>
      {props.icon}
    </div>
  );
}

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    stepper: {
      padding: 0,
      margin: '0 -1rem'
    },
    stepContainer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  })
);

type IStep = {
  label: string;
  icon: ReactNode;
  component: JSX.Element;
};

interface IProps {
  steps: IStep[];
  activeStep: number;
}

const Stepper: FC<IProps> = (props) => {
  const classes = useStyles();

  const { steps, activeStep } = props;

  return (
    <Box className={classes.root}>
      <MuiStepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        className={classes.stepper}>
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel StepIconComponent={ColorlibStepIcon} StepIconProps={{ icon: step.icon }}>
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </MuiStepper>
      <Box>
        <Box className={classes.stepContainer}>{steps[activeStep].component}</Box>
      </Box>
    </Box>
  );
};

export default Stepper;
