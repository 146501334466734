import React, { FC, FocusEvent } from 'react';
import InputMask from 'react-input-mask';

import { TextField, TextFieldProps } from '@material-ui/core';
import { useField } from 'formik';

type IProps = TextFieldProps & { mask?: string };

const Input: FC<IProps> = (props) => {
  const [field, meta] = useField(props.name);

  function handleBlur(event: FocusEvent<HTMLInputElement>) {
    field.onBlur(event);

    if (props.onBlur) {
      props.onBlur(event);
    }
  }

  return (
    <InputMask
      mask={props.mask}
      maskChar=""
      onChange={field.onChange}
      onBlur={handleBlur}
      value={field.value}
      disabled={props.disabled}>
      {() => {
        return (
          <TextField
            {...props}
            {...field}
            onBlur={handleBlur}
            error={meta.touched && !!meta.error}
            helperText={(meta.touched && meta.error) || ''}
            margin="dense"
            fullWidth
          />
        );
      }}
    </InputMask>
  );

  // return (
  //   <TextField
  //     {...props}
  //     {...field}
  //     onBlur={handleBlur}
  //     error={meta.touched && !!meta.error}
  //     helperText={meta.touched && meta.error}
  //     margin="dense"
  //     fullWidth
  //   />
  // );
};

export default Input;
