import * as Yup from 'yup';

import { IForgotPasswordForm } from './interfaces';

export const FORGOT_PASSWORD_INITIAL_VALUES: IForgotPasswordForm = {
  email: ''
};

export const ForgotPasswordValidationSchema = Yup.object().shape<IForgotPasswordForm>({
  email: Yup.string().required('Campo obrigatório').email('E-mail inválido')
});
