import React, { FC, useContext, useState } from 'react';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Formik, FormikProps } from 'formik';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';

import InputPassword from '@components/form/InputPassword';
import Button from '@components/shared/Button';
import analytics from '@config/analytics';
import { PATHS } from '@config/paths';
import { resetPassword } from '@services/auth';

import { UIContext } from '../../context/UIContext';
import { RECOVERY_PASSWORD_INITIAL_VALUES, RecoveryPasswordValidationSchema } from './helpers';
import { IResetPasswordForm } from './interfaces';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem 2rem'
  },
  padding: {
    padding: '1rem 2rem'
  },
  actionsContainer: {
    marginTop: '1rem',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  alignRight: {
    marginLeft: 'auto'
  }
});

const ResetPassword: FC = () => {
  const classes = useStyles();
  const router = useRouter();

  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const fullScreen = useMediaQuery('(max-width:400px)');

  const { enqueueSnackbar } = useSnackbar();
  const uiContext = useContext(UIContext);

  const [loading, setLoading] = useState(false);

  function handleClose() {
    uiContext.resetPassword.toggleModal(false);
  }

  function handleCloseRecoveryPasswordModal() {
    handleClose();

    analytics.event('Fechar', {
      event_category: 'Modal esqueci minha senha',
      event_label: 'Botão Fechar'
    });
  }

  function handleSubmit(values: IResetPasswordForm) {
    analytics.event('Esqueci minha senha', {
      event_category: 'Modal esqueci minha senha',
      event_label: 'Botão Enviar'
    });

    setLoading(true);

    resetPassword({
      ...values,
      code: router.query.resetPasswordToken as string
    })
      .then(() => {
        router.push(PATHS.ROOT);

        uiContext.resetPassword.toggleModal(false);
        uiContext.login.toggleModal(true);

        enqueueSnackbar(`Sua senha foi redefinida com sucesso`, {
          variant: 'success'
        });
      })
      .catch(() => {
        enqueueSnackbar('Algo de errado aconteceu', { variant: 'error' });
      })
      .finally(() => setLoading(false));
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={uiContext.resetPassword.resetPasswordModalIsOpen}
      onClose={handleClose}>
      <Formik
        initialValues={RECOVERY_PASSWORD_INITIAL_VALUES}
        validationSchema={RecoveryPasswordValidationSchema}
        onSubmit={handleSubmit}>
        {(formik: FormikProps<IResetPasswordForm>) => {
          return (
            <Form>
              <Box className={classes.headerContainer}>
                <DialogTitle style={{ padding: 0 }}>Recuperação de senha</DialogTitle>
                <IconButton onClick={handleCloseRecoveryPasswordModal} size="small">
                  <CloseIcon />
                </IconButton>
              </Box>
              <DialogContent className={classes.padding}>
                <DialogContentText>Preencha os campos abaixo com sua nova senha!</DialogContentText>

                <InputPassword name="password" id="password" label="Senha" />
                <InputPassword
                  name="passwordConfirmation"
                  id="passwordConfirmation"
                  label="Confirmar Senha"
                />
              </DialogContent>

              <DialogActions className={`${classes.actionsContainer} ${classes.padding}`}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.alignRight}
                  loading={loading}
                  disabled={!(formik.isValid && formik.dirty) || loading}>
                  Enviar
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default ResetPassword;
