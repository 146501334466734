import { Coupon, Enum_Coupon_Type } from '@graphql/generated';

export function getDeliveryDayMessage(): string {
  const date = new Date();
  const hour = date.getHours();
  const isSaturday = date.getDay() === 6;
  const canDeliveryToday = hour < 12;
  let deliveryDay = canDeliveryToday ? 'ainda hoje' : 'amanhã';

  if (isSaturday && !canDeliveryToday) {
    deliveryDay = 'na segunda-feira';
  }

  return `Entregaremos seu pedido ${deliveryDay}. ❤️`;
}

export const deliveryFees = {
  Sorocaba: 0,
  Votorantim: 0,
  'Araçoiaba da Serra': 0
};

export function getDeliveryFee(orderValue: number, coupon?: Coupon): number {
  if (coupon?.type === Enum_Coupon_Type.FreeDelivery) {
    return 0;
  }

  const fees = [
    { order: 25, fee: 11 },
    { order: 50, fee: 9 },
    { order: 100, fee: 5 },
    { order: 150, fee: 3 },
    { order: 9999, fee: 0 }
  ];

  const selectedFee = fees.find((fee) => orderValue <= fee.order);
  return selectedFee.fee;
}
