import React, { FC } from 'react';

import { Box, Grid, Link, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EmailIconOutlined from '@material-ui/icons/EmailOutlined';
import InstagramIcon from '@material-ui/icons/Instagram';
import LocalShippingIconOutlined from '@material-ui/icons/LocalShippingOutlined';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import analytics from '@config/analytics';
import { EMAIL, INSTAGRAM, PHONE_NUMBER } from '@config/constants';
import { getWhatsappMessageURL } from '@helpers/misc';

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    background: theme.palette.common.white,
    borderTop: `1px solid #ddd`,
    padding: '2rem'
  },
  creditsContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    padding: '0.5rem 0'
  }
}));

const Footer: FC = () => {
  const classes = useStyles();

  function collectMetrics(action: string) {
    analytics.event(action, {
      event_category: 'Contato',
      event_label: 'Rodapé'
    });
  }

  return (
    <footer className={classes.container}>
      <Grid container spacing={8}>
        {/* <Hidden smDown>
          <Grid item md={5}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <img src="/images/logo-only.png" alt="logo" width="110px" height="130px" />
            </Box>
          </Grid>
        </Hidden> */}

        <Grid item xs={12} sm={7} md={4}>
          <Typography variant="h5" component="h6" gutterBottom>
            Atendimento
          </Typography>

          <Box display="flex">
            <Box mr={1}>
              <PlaceOutlinedIcon />
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography>
                <b>Sorocaba e região</b>
              </Typography>
            </Box>
          </Box>

          <Box display="flex">
            <Box mr={1}>
              <ScheduleOutlinedIcon />
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography>
                <b>Horários de funcionamento</b>
              </Typography>

              <Typography variant="body2">Segunda a sexta - 07:30h às 19:00h</Typography>
              <Typography variant="body2">Sábado - 07:30h às 17:00h</Typography>
              {/* <Typography variant="body2">Domingos e feriados - 08:00h às 13:00h</Typography> */}
            </Box>
          </Box>

          <Box display="flex">
            <Box mr={1}>
              <LocalShippingIconOutlined />
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography>
                <b>Delivery</b>
              </Typography>

              <Typography variant="body2">Entregamos no seu lar de segunda a sábado</Typography>
              <Typography variant="body2">
                Pedidos realizados até 12h serão entregues no mesmo dia, após esse horário as
                entregas ocorrerão no dia seguinte
              </Typography>
              <Typography variant="body2">
                ** Feriados: entrar em contato para informações
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={5} md={3}>
          <Typography variant="h5" component="h6" gutterBottom>
            Contatos
          </Typography>

          <Box display="flex">
            <Box mr={1}>
              <WhatsAppIcon />
            </Box>
            <Typography>
              <Link
                href={getWhatsappMessageURL('Olá, poderia me ajudar?', PHONE_NUMBER)}
                onClick={() => collectMetrics('WhatsApp')}
                color="inherit"
                target="_blank"
                rel="noopener noreferrer">
                {PHONE_NUMBER}
              </Link>
            </Typography>
          </Box>
          <Box display="flex">
            <Box mr={1}>
              <InstagramIcon />
            </Box>
            <Typography>
              <Link
                href={`https://instagram.com/${INSTAGRAM}`}
                onClick={() => collectMetrics('Instagram')}
                color="inherit"
                target="_blank"
                rel="noopener noreferrer">
                @{INSTAGRAM}
              </Link>
            </Typography>
          </Box>
          <Box display="flex">
            <Box mr={1}>
              <EmailIconOutlined />
            </Box>

            <Typography>
              <Link
                onClick={() => collectMetrics('Email')}
                href={`mailto:${EMAIL}`}
                color="inherit"
                target="_blank"
                rel="noopener noreferrer">
                {EMAIL}
              </Link>
            </Typography>
          </Box>
        </Grid>
        <Box className={classes.creditsContainer}>
          <Typography variant="caption">
            <Link
              onClick={() => collectMetrics('Créditos')}
              href="mailto:gabrieltosta3@gmail.com"
              color="inherit"
              target="_blank"
              rel="noopener noreferrer">
              Desenvolvido com <span style={{ color: '#E90606' }}>&#10084;</span> - Gabriel Tosta
            </Link>
          </Typography>
        </Box>
      </Grid>
    </footer>
  );
};

export default Footer;
