import React, { FC, ReactNode } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ptBR } from '@material-ui/core/locale';
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider as MuiThemeProvider
} from '@material-ui/core/styles';

let theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#23A61B' },
      // secondary: { main: '#FE7801', contrastText: '#fff' },
      secondary: { main: '#E41A4C' }
    }
  },
  ptBR
);

theme = responsiveFontSizes(theme);
interface IProps {
  children: ReactNode;
}

const AppTheme: FC<IProps> = ({ children }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />

      {children}
    </MuiThemeProvider>
  );
};

export default AppTheme;
