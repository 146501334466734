import { UserInput } from '@graphql/generated';

import { API_URL } from './constants';

export function isSSR(): boolean {
  return !process.browser;
}

export function isMobile(): boolean {
  return !isSSR() && window.innerWidth < 768;
}

export function isSuperAdmin(user: UserInput): boolean {
  return (user?.role as any)?.type === 'super_admin';
}

export function currency(value: number): string {
  return (value || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

export function getImageURL(img: { url?: string }): string {
  const fallback = '/images/product-placeholder.png';

  return img?.url ? API_URL + img.url : fallback;
}

export function getBannerURL(img: { url?: string }): string {
  const fallback = '/images/banner.jpg';

  return img?.url ? API_URL + img.url : fallback;
}
