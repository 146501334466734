import '@config/sentry';

import React, { useEffect } from 'react';

import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';

import AppTheme from '@assets/theme';
import Layout from '@components/layout';
import analytics from '@config/analytics';
import ApolloProvider from '@config/apollo';
import { APP_NAME } from '@config/constants';
import { PATHS } from '@config/paths';

import RootContextProvider from '../context';
import ForgotPassword from './forgot-password';
import Login from './login';
import Register from './register';
import ResetPassword from './reset-password';

import './index.css';

const AppPage: NextPage<AppProps> = (props) => {
  const router = useRouter();

  // Workaround for https://github.com/vercel/next.js/issues/8592
  const err = (props as any).err;
  const { Component, pageProps } = props;

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    const url = window.location.pathname;

    if (url !== PATHS.ROOT) {
      analytics.pageview(url);
    }
  }, []);

  useEffect(() => {
    const handleRouteChangeComplete = (url: string) => {
      // only send events with category defined (Root component handles it)
      // ignores next route trigger when path has params (REF: https://github.com/vercel/next.js/issues/11639)
      // (url === PATHS.ROOT || router.asPath === url)
      if (url !== PATHS.ROOT) {
        analytics.pageview(url);
      }
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <title>{APP_NAME}</title>
      </Head>

      <ApolloProvider>
        <RootContextProvider>
          <AppTheme>
            <Layout>
              <Login />
              <Register />
              <ForgotPassword />
              <ResetPassword />
              <Component {...pageProps} err={err} />
            </Layout>
          </AppTheme>
        </RootContextProvider>
      </ApolloProvider>
    </>
  );
};

export default AppPage;
