import React, { FC, useEffect, useState, createContext, useContext } from 'react';

import analytics from '@config/analytics';
import { apolloClient } from '@config/apollo';
import { currency } from '@config/utils';
import {
  Coupon,
  Enum_Orderitem_Unit_Type,
  Product,
  ProductsDocument,
  ProductsQuery,
  ProductsQueryVariables
} from '@graphql/generated';
import { getPrice } from '@helpers/products';
import { COOKIES, getCookie, setCookie } from '@services/cookies';

import { UserContext } from './UserContext';

export interface ICartItem {
  product: Product;
  unitType: Enum_Orderitem_Unit_Type;
  quantity: number;
  details?: string;
}

export interface ICart {
  items: Array<ICartItem>;
  addItem: (item: ICartItem) => void;
  removeItem: (item: ICartItem) => void;
  getTotal: () => number;
  clear: () => void;

  coupon: Coupon;
  setCoupon: (coupon: Coupon) => void;

  takeAway: boolean;
  setTakeAway: (takeAway: boolean) => void;
}

export type ICartContext = ICart;

export const CART_CONTEXT_INITIAL_VALUES = {
  items: [],
  addItem: undefined,
  removeItem: undefined,
  getTotal: undefined,
  clear: undefined,

  coupon: undefined,
  setCoupon: undefined,

  takeAway: false,
  setTakeAway: undefined
};

export const CartContext = createContext<ICartContext>(CART_CONTEXT_INITIAL_VALUES);

const CartContextProvider: FC = ({ children }) => {
  const userContext = useContext(UserContext);

  const [items, setItems] = useState<ICartContext['items']>(CART_CONTEXT_INITIAL_VALUES.items);
  const [coupon, setCoupon] = useState<ICartContext['coupon']>(CART_CONTEXT_INITIAL_VALUES.coupon);
  const [takeAway, setTakeAway] = useState<ICartContext['takeAway']>(
    CART_CONTEXT_INITIAL_VALUES.takeAway
  );

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (items.length > 0) {
        analytics.event('Abandono de Carrinho', {
          event_category: 'Checkout',
          user: userContext.user?.email || 'Visitante',
          items: items.map((item) => ({
            item_id: item.product.id,
            item_name: item.product.name,
            item_category: item.unitType,
            price: currency(getPrice(item.product, item.unitType)),
            quantity: item.quantity
          }))
        });
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [items, userContext.user?.email]);

  useEffect(() => {
    const cart = getCookie(COOKIES.Cart);

    if (cart) {
      const cartItems: ICartContext['items'] = JSON.parse(cart);
      updateCartItems(cartItems);
    }

    async function updateCartItems(cartItems: ICartItem[]) {
      const response = await apolloClient.query<ProductsQuery, ProductsQueryVariables>({
        query: ProductsDocument,
        variables: { productsId: cartItems.map((item) => item.product.id) }
      });

      const newCartItems = cartItems
        .map((item) => {
          return {
            ...item,
            product: response.data.products.find((product) => item.product.id === product.id)
          } as ICartItem;
        })
        .filter((item) => {
          const { product, quantity, unitType } = item;

          const isValidItem = product && quantity && unitType;
          if (isValidItem) {
            const hasValidPrice = !!getPrice(product, unitType);
            const hasValidUnitType =
              (unitType === Enum_Orderitem_Unit_Type.Unit && product.sale_by_unit_enabled) ||
              (unitType === Enum_Orderitem_Unit_Type.Weight && product.sale_by_weight_enabled);

            return hasValidPrice && hasValidUnitType;
          }
        });

      updateItems(newCartItems as ICart['items']);
    }
  }, []);

  function updateItems(items: ICart['items']) {
    setItems(items);

    /**
     * Avoid too large data in cookies.
     * 1 reason - cookies has limited length
     * 2 reason - api/graphql/apollo does not fetch with too many ids.
     */
    const cartCookie = items.slice(0, 20).map((item) => ({
      product: { id: item.product.id },
      quantity: item.quantity,
      unitType: item.unitType,
      details: item.details
    }));

    setCookie(COOKIES.Cart, JSON.stringify(cartCookie));
  }

  function addItem(item: ICartItem): void {
    let newCartItems;

    const index = items.findIndex((i) => i.product.id === item.product.id);
    if (index === -1) {
      newCartItems = items.concat(item);
    } else {
      newCartItems = Object.assign([], items, { [index]: item });
    }

    updateItems(newCartItems);
  }

  function removeItem(item: ICartItem): void {
    let newCartItems;

    if (item.quantity === 0) {
      newCartItems = items.filter((i) => i.product.id !== item.product.id);
    } else {
      newCartItems = items.map((i) => {
        if (i.product.id === item.product.id) {
          return item;
        }

        return i;
      });
    }

    updateItems(newCartItems);
  }

  function getTotal(): number {
    return items.reduce((total, item) => {
      total += getPrice(item.product, item.unitType) * item.quantity;

      return total;
    }, 0);
  }

  function clear(): void {
    updateItems(CART_CONTEXT_INITIAL_VALUES.items);
  }

  const cartContext: ICartContext = {
    items: items,
    addItem: addItem,
    removeItem: removeItem,
    getTotal: getTotal,
    clear: clear,

    coupon,
    setCoupon,

    takeAway,
    setTakeAway
  };

  return <CartContext.Provider value={cartContext}>{children}</CartContext.Provider>;
};

export default CartContextProvider;
