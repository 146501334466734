import React, { FC, ReactElement } from 'react';

import { List, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { IMenuListItem } from '../MenuListItem';

const useStyles = makeStyles<Theme, { isDrawer: boolean }>((theme) =>
  createStyles({
    verticalDrawerContainer: {
      position: 'sticky',
      alignSelf: 'flex-start',
      top: theme.mixins.toolbar.minHeight,
      flexShrink: 0,
      overflow: 'auto',
      width: 280,
      background: '#fff',
      paddingTop: ({ isDrawer }) => (isDrawer ? undefined : '1rem'),

      [theme.breakpoints.down('md')]: {
        display: ({ isDrawer }) => (isDrawer ? 'flex' : 'none')
      }
    },
    horizontalDrawerContainer: {
      display: 'none',
      width: '100%',
      overflowX: 'auto',
      marginBottom: '8px', // Grid negative margin (8px issue)
      borderBottom: '1px solid #ddd',

      [theme.breakpoints.down('md')]: {
        display: 'flex'
      }
    },
    verticalList: {
      width: '100%'
    },
    horizontalList: {
      display: 'none',
      width: '100%',
      overflowX: 'auto',
      paddingTop: 0,
      paddingBottom: '8px',

      [theme.breakpoints.down('md')]: {
        display: 'flex'
      },

      '& > li': {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: '48px',
        minWidth: '100px',

        [theme.breakpoints.down('xs')]: {
          minWidth: '28%'
        }
      },

      '& > li > a': {
        textAlign: 'center',
        padding: '4px 8px',
        height: '48px'
      }
    }
  })
);

interface IMenuList {
  isDrawer?: boolean;
  direction: 'vertical' | 'horizontal';
  items: ReactElement<IMenuListItem> | Array<ReactElement<IMenuListItem>>;
}

const MenuList: FC<IMenuList> = (props) => {
  const { isDrawer, direction, items } = props;
  const classes = useStyles({ isDrawer });

  const isVertical = direction === 'vertical';

  const listClasses = {
    container: isVertical ? classes.verticalDrawerContainer : classes.horizontalDrawerContainer,
    list: isVertical ? classes.verticalList : classes.horizontalList
  };

  return (
    <nav className={listClasses.container}>
      <List className={listClasses.list}>{items}</List>
    </nav>
  );
};

export default MenuList;
