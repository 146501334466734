import * as Yup from 'yup';

import { ICredentialsForm, IUserForm, IAddressForm, IRegisterForm } from './interfaces';

export const REGISTER_INITIAL_VALUES: IRegisterForm = {
  email: '',
  password: '',
  confirm_password: '',
  name: '',
  surname: '',
  birth_date: '',
  gender: '',
  phone_number: '',
  house_reference: '',
  zip_code: '',
  street: '',
  house_number: '',
  neighborhood: '',
  city: '',
  state: ''
};

export const RegisterCredentialsValidationSchema = Yup.object().shape<ICredentialsForm>({
  email: Yup.string().required('Campo obrigatório').email('E-mail inválido'),
  password: Yup.string().required('Campo obrigatório'),
  confirm_password: Yup.string()
    .required('Campo obrigatório')
    .oneOf([Yup.ref('password'), null], 'As senhas devem ser idênticas')
});

export const RegisterUserValidationSchema = Yup.object().shape<IUserForm>({
  name: Yup.string().required('Campo obrigatório'),
  surname: Yup.string().required('Campo obrigatório'),
  birth_date: Yup.string().required('Campo obrigatório'),
  gender: Yup.string().required('Campo obrigatório'),
  phone_number: Yup.string().required('Campo obrigatório')
});

export const RegisterAddressValidationSchema = Yup.object().shape<IAddressForm>({
  zip_code: Yup.string().required('Campo obrigatório'),
  street: Yup.string().required('Campo obrigatório'),
  house_number: Yup.string().required('Campo obrigatório'),
  house_reference: Yup.string(),
  neighborhood: Yup.string().required('Campo obrigatório'),
  city: Yup.string().required('Campo obrigatório'),
  state: Yup.string()
});

export const RegisterValidationSchema = [
  RegisterCredentialsValidationSchema,
  RegisterUserValidationSchema,
  RegisterAddressValidationSchema
];

export const UpdateRegisterValidationSchema = Yup.object()
  .concat(RegisterUserValidationSchema)
  .concat(RegisterAddressValidationSchema);
