import React, { FC, useContext, useEffect, useRef, useState, ChangeEvent } from 'react';

import { Box, Fade, IconButton, Tooltip, InputBase } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { useDebounce } from 'use-debounce';

import analytics from '@config/analytics';

import { UIContext } from '../../../context/UIContext';

const useStyles = makeStyles<Theme, { searchActive: boolean }>((theme) => ({
  root: {
    display: 'flex',
    width: ({ searchActive }) => (searchActive ? '300px' : '48px'),
    transition: theme.transitions.create('width'),
    backgroundColor: ({ searchActive }) =>
      searchActive ? theme.palette.grey[200] : theme.palette.grey[100],

    [theme.breakpoints.down('md')]: {
      width: ({ searchActive }) => searchActive && '100%'
    }
  },
  inputRoot: {
    width: '100%'
  }
}));

const Search: FC = () => {
  const {
    search: { query, setQuery, setTyping }
  } = useContext(UIContext);
  const [value, setValue] = useState('');
  const [debouncedValue] = useDebounce(value, 500);

  const [searchActive, setSearchActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const classes = useStyles({ searchActive });

  useEffect(() => {
    if (searchActive) {
      inputRef?.current?.focus();
    }
  }, [searchActive, inputRef]);

  useEffect(() => {
    if (searchActive && debouncedValue !== query) {
      setTyping(false);
      setQuery(debouncedValue);
    }
  }, [debouncedValue, searchActive, query, setQuery, setTyping]);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
    setTyping(true);
  }

  function toggleSearch() {
    const toggle = !searchActive;
    setSearchActive(toggle);

    analytics.event(toggle ? 'Abrir' : 'Fechar', {
      event_category: 'Menu Topo',
      event_label: 'Busca'
    });
  }

  function clearSearchContent() {
    setValue('');

    analytics.event('Limpar', {
      event_category: 'Menu Topo',
      event_label: 'Busca'
    });
  }

  return (
    <Box className={classes.root}>
      <Tooltip title="Buscar">
        <IconButton onClick={toggleSearch}>
          <SearchIcon />
        </IconButton>
      </Tooltip>

      <Box display="flex" position="relative" width="100%">
        <InputBase
          placeholder="Buscar Produtos"
          onChange={handleChange}
          value={value}
          inputRef={inputRef}
          classes={{ root: classes.inputRoot }}
        />

        <Fade in={searchActive && !!value}>
          <Tooltip title="Limpar">
            <IconButton onClick={clearSearchContent}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Fade>
      </Box>
    </Box>
  );
};

export default Search;
