import React, { FC } from 'react';

import { Box, TextField, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDebouncedCallback } from 'use-debounce';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: 32
  },
  input: {
    fontSize: `${theme.typography.body2.fontSize}`
  }
}));

interface IProps {
  details: string;
  onSave: (details: string) => void;
}

const ProductDetailsInput: FC<IProps> = (props) => {
  const { details, onSave } = props;
  const classes = useStyles();

  const debounced = useDebouncedCallback(onSave, 300);

  return (
    <Box className={classes.root}>
      <TextField
        fullWidth
        placeholder="Alguma observação?"
        size="small"
        defaultValue={details}
        onChange={(e) => debounced(e.target.value)}
        InputProps={{ className: classes.input }}
      />
    </Box>
  );
};

export default ProductDetailsInput;
