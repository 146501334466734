import React, { FC, KeyboardEvent, MouseEvent, useContext } from 'react';

import { Box, Divider, Drawer, IconButton, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Image from 'next/image';

import analytics from '@config/analytics';
import { ADMIN_PATHS, USER_PATHS } from '@config/paths';
import { isSuperAdmin } from '@config/utils';
import { logout } from '@services/authentication';

import { UIContext } from '../../../context/UIContext';
import { UserContext } from '../../../context/UserContext';
import CategoriesMenu from '../../shared/CategoriesMenu';
import MenuList from './MenuList';
import MenuListItem from './MenuListItem';
import UserDrawerMenu from './UserDrawerMenu';

interface IProps {
  toggleMenuDrawer: (event: KeyboardEvent | MouseEvent) => void;
  menuDrawer: boolean;
}

const useStyles = makeStyles<Theme>(() => ({
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    position: 'relative'
  },
  closeDrawerButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: 'calc(1rem - 3px)',
    padding: '3px' // inherits from MUI...
  }
}));

const MenuDrawer: FC<IProps> = (props) => {
  const classes = useStyles();
  const { toggleMenuDrawer, menuDrawer } = props;
  const direction = 'vertical';

  const { user, setUser } = useContext(UserContext);
  const uiContext = useContext(UIContext);

  function handleLogout() {
    logout();
    setUser(null);

    analytics.event('Logout', {
      event_category: 'Menu Hamburguer'
    });
  }

  function handleLogin() {
    uiContext.login.toggleModal(true);

    analytics.event('Login', {
      event_category: 'Menu Hamburguer'
    });
  }

  function handleRegister() {
    uiContext.register.toggleModal(true);

    analytics.event('Cadastrar', {
      event_category: 'Menu Hamburguer'
    });
  }

  function closeMenu(event: KeyboardEvent | MouseEvent) {
    toggleMenuDrawer(event);

    analytics.event('Fechar', {
      event_category: 'Menu Hamburguer',
      event_label: 'Botão Fechar'
    });
  }

  function collectAnalytics(slug: string) {
    return (event: KeyboardEvent | MouseEvent) => {
      toggleMenuDrawer(event);

      analytics.event('Click', {
        event_category: 'Menu Hamburguer',
        event_label: slug
      });
    };
  }

  return (
    <Drawer anchor="left" open={menuDrawer} onClose={toggleMenuDrawer}>
      <Box className={classes.logoContainer}>
        <Image src="/images/logo-text.png" alt="logo" width={170} height={34} />

        <IconButton className={classes.closeDrawerButton} onClick={closeMenu} size="small">
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <UserDrawerMenu
        user={user}
        logout={handleLogout}
        register={handleRegister}
        login={handleLogin}
      />
      <Divider />

      {isSuperAdmin(user) && (
        <>
          <MenuList
            isDrawer
            direction={direction}
            items={ADMIN_PATHS.map((option) => (
              <MenuListItem
                key={option.value}
                href={option.value}
                label={option.label}
                direction={direction}
                onClick={collectAnalytics(option.value)}
              />
            ))}
          />
          <Divider />
        </>
      )}

      {user && (
        <>
          <MenuList
            isDrawer
            direction={direction}
            items={USER_PATHS.map((option) => (
              <MenuListItem
                key={option.value}
                href={option.value}
                label={option.label}
                direction={direction}
                onClick={collectAnalytics(option.value)}
              />
            ))}
          />
          <Divider />
        </>
      )}

      <CategoriesMenu direction={direction} onClick={toggleMenuDrawer} isDrawer />
    </Drawer>
  );
};

export default MenuDrawer;
