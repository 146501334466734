import React, { FC } from 'react';

import CartContextProvider from './CartContext';
import ProductCategoryContextProvider from './ProductCategoryContext';
import UIContextProvider from './UIContext';
import UserContextProvider from './UserContext';

const RootContextProvider: FC = ({ children }) => {
  return (
    <UIContextProvider>
      <UserContextProvider>
        <CartContextProvider>
          <ProductCategoryContextProvider>{children}</ProductCategoryContextProvider>
        </CartContextProvider>
      </UserContextProvider>
    </UIContextProvider>
  );
};

export default RootContextProvider;
