/**
 * General
 */
export const NODE_ENV = process.env.NODE_ENV;
export const CI = process.env.CI;

const DEV_API_URL = 'https://api.quitandadivino.com.br';
// const DEV_API_URL = 'http://localhost:1337';
export const API_URL = NODE_ENV === 'production' ? process.env.NEXT_PUBLIC_API_URL : DEV_API_URL;

/**
 * Analytics
 */
export const GOOGLE_ANALYTICS_PIXEL = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_PIXEL;
export const FACEBOOK_PIXEL = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL;
export const HOTJAR_PIXEL = process.env.NEXT_PUBLIC_HOTJAR_PIXEL;

/**
 * Application
 */
export const UNIT_WEIGHT = process.env.NEXT_PUBLIC_UNIT_WEIGHT as 'FROM_SERVER' | 'FROM_APP';
export const DELIVERY_TIME_PREFERENCE = !!process.env.NEXT_PUBLIC_DELIVERY_TIME_PREFERENCE;

export const APP_NAME = 'Quitanda Divino';
export const INSTAGRAM = 'quitandadivino_oficial';
export const EMAIL = 'quitandadivino@hotmail.com';
export const PHONE_NUMBER = '(15) 99132-7673';

/**
 * SENTRY
 */
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
export const GIT_COMMIT_SHA = process.env.NEXT_PUBLIC_GIT_COMMIT_SHA; // next.config.js
export const SENTRY_SERVER_ROOT_DIR = process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR;
