import React, { FC, ReactNode } from 'react';

import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';

interface IProps {
  children: ReactNode;
}

const SnackbarProvider: FC<IProps> = ({ children }) => {
  return (
    <NotistackSnackbarProvider
      maxSnack={3}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      {children}
    </NotistackSnackbarProvider>
  );
};

export default SnackbarProvider;
