import * as Yup from 'yup';

import { ILoginForm } from './interfaces';

export const LOGIN_INITIAL_VALUES: ILoginForm = { email: '', password: '' };

export const LoginValidationSchema = Yup.object().shape<ILoginForm>({
  email: Yup.string().required('Campo obrigatório').email('E-mail inválido'),
  password: Yup.string().required('Campo obrigatório')
});
