import { RewriteFrames } from '@sentry/integrations';
import * as Sentry from '@sentry/node';

import { GIT_COMMIT_SHA, NODE_ENV, SENTRY_DSN } from './constants';

// https://leerob.io/blog/configuring-sentry-for-nextjs-apps
function startSentry() {
  if (!SENTRY_DSN) {
    return;
  }

  const integrations = [];
  if (process.env.NEXT_IS_SERVER === 'true' && process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR) {
    // For Node.js, rewrite Error.stack to use relative paths, so that source
    // maps starting with ~/_next map to files in Error.stack with path
    // app:///_next
    integrations.push(
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(
            process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
            'app:///'
          );
          frame.filename = frame.filename.replace('.next', '_next');
          return frame;
        }
      })
    );
  }

  Sentry.init({
    enabled: NODE_ENV === 'production',
    dsn: SENTRY_DSN,
    environment: NODE_ENV,
    release: GIT_COMMIT_SHA,
    integrations
  });
}

startSentry();
