import React, { FC, KeyboardEvent, MouseEvent, useState } from 'react';

import { Box } from '@material-ui/core';

import analytics from '@config/analytics';
import { isMobile } from '@config/utils';

import CartDrawer from './CartDrawer';
import Footer from './Footer';
import Header from './Header';
import MenuDrawer from './MenuDrawer';
import SnackbarProvider from './ui/Snackbar';
import WhatsAppFAB from './WhatsAppFAB';

export type IDrawerDirection = 'bottom' | 'right';
export type IDrawerState = { [anchor in IDrawerDirection]: boolean };
export const drawerDirections: IDrawerDirection[] = ['bottom', 'right'];

const Layout: FC = (props) => {
  const [menuDrawer, setMenuDrawer] = useState<boolean>(false);
  const [cartDrawer, setCartDrawer] = useState<IDrawerState>({
    bottom: false,
    right: false
  });

  function preventDrawer(event: KeyboardEvent | MouseEvent) {
    if (
      event.type === 'keydown' &&
      ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
  }

  function toggleCartDrawer(event: KeyboardEvent | MouseEvent) {
    preventDrawer(event);

    const anchor: IDrawerDirection = isMobile() ? 'bottom' : 'right';
    const toggle = !cartDrawer[anchor];
    setCartDrawer({
      ...cartDrawer,
      [anchor]: !cartDrawer[anchor]
    });

    analytics.event(toggle ? 'Abrir' : 'Fechar', {
      event_category: 'Menu Topo',
      event_label: 'Carrinho'
    });
  }

  function toggleMenuDrawer(event: KeyboardEvent | MouseEvent) {
    const toggle = !menuDrawer;

    preventDrawer(event);
    setMenuDrawer(toggle);

    if (toggle) {
      analytics.event('Abrir', {
        event_category: 'Menu Topo',
        event_label: 'Hamburguer'
      });
    }
  }

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <SnackbarProvider>
        <Header toggleMenuDrawer={toggleMenuDrawer} toggleCartDrawer={toggleCartDrawer} />
        <MenuDrawer menuDrawer={menuDrawer} toggleMenuDrawer={toggleMenuDrawer} />
        <CartDrawer cartDrawer={cartDrawer} toggleCartDrawer={toggleCartDrawer} />
        <Box flexGrow={1}>{props.children}</Box>

        <WhatsAppFAB />
        <Footer />
      </SnackbarProvider>
    </Box>
  );
};

export default Layout;
