import React, { FC } from 'react';

import Input from '@components/form/Input';
import InputPassword from '@components/form/InputPassword';

const CredentialsForm: FC = () => {
  return (
    <>
      <Input name="email" id="email" label="E-mail" />

      <InputPassword name="password" id="password" label="Senha" />
      <InputPassword name="confirm_password" id="confirm_password" label="Confirmar Senha" />
    </>
  );
};
export default CredentialsForm;
