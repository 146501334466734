import React, { FC } from 'react';

import { List, ListItem, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ICartItem } from '@context/CartContext';

import CartProduct from '../CartProduct';

const useStyles = makeStyles<Theme>((theme) => ({
  list: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
    padding: '1rem 2rem',

    [theme.breakpoints.down('md')]: {
      padding: '1rem'
    }
  },
  listItem: {
    marginBottom: '1rem',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,

    '&:last-child': {
      marginBottom: 0,
      borderBottom: 0
    }
  }
}));

interface IProps {
  items: ICartItem[];
}

const CartList: FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      {props.items.map((item) => {
        return (
          <ListItem key={item.product.id} className={classes.listItem} disableGutters>
            <CartProduct item={item} />
          </ListItem>
        );
      })}
    </List>
  );
};
export default CartList;
