import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Long` scalar type represents 52-bit integers */
  Long: any;
  /** A time string with format: HH:mm:ss.SSS */
  Time: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AdminUser = {
  __typename?: 'AdminUser';
  id: Scalars['ID'];
  username?: Maybe<Scalars['String']>;
  firstname: Scalars['String'];
  lastname: Scalars['String'];
};

export type ComponentDeliveryFeeFee = {
  __typename?: 'ComponentDeliveryFeeFee';
  id: Scalars['ID'];
  distance?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  order_subtotal_to_free_delivery?: Maybe<Scalars['Float']>;
};

export type ComponentDeliveryFeeFeeInput = {
  distance?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  order_subtotal_to_free_delivery?: Maybe<Scalars['Float']>;
};

export type Coupon = {
  __typename?: 'Coupon';
  id: Scalars['ID'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  type: Enum_Coupon_Type;
  name: Scalars['String'];
  discount: Scalars['Int'];
  enabled: Scalars['Boolean'];
};

export type CouponAggregator = {
  __typename?: 'CouponAggregator';
  count?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
  sum?: Maybe<CouponAggregatorSum>;
  avg?: Maybe<CouponAggregatorAvg>;
  min?: Maybe<CouponAggregatorMin>;
  max?: Maybe<CouponAggregatorMax>;
};

export type CouponAggregatorAvg = {
  __typename?: 'CouponAggregatorAvg';
  discount?: Maybe<Scalars['Float']>;
};

export type CouponAggregatorMax = {
  __typename?: 'CouponAggregatorMax';
  discount?: Maybe<Scalars['Float']>;
};

export type CouponAggregatorMin = {
  __typename?: 'CouponAggregatorMin';
  discount?: Maybe<Scalars['Float']>;
};

export type CouponAggregatorSum = {
  __typename?: 'CouponAggregatorSum';
  discount?: Maybe<Scalars['Float']>;
};

export type CouponConnection = {
  __typename?: 'CouponConnection';
  values?: Maybe<Array<Maybe<Coupon>>>;
  groupBy?: Maybe<CouponGroupBy>;
  aggregate?: Maybe<CouponAggregator>;
};

export type CouponConnectionCreated_At = {
  __typename?: 'CouponConnectionCreated_at';
  key?: Maybe<Scalars['DateTime']>;
  connection?: Maybe<CouponConnection>;
};

export type CouponConnectionDiscount = {
  __typename?: 'CouponConnectionDiscount';
  key?: Maybe<Scalars['Int']>;
  connection?: Maybe<CouponConnection>;
};

export type CouponConnectionEnabled = {
  __typename?: 'CouponConnectionEnabled';
  key?: Maybe<Scalars['Boolean']>;
  connection?: Maybe<CouponConnection>;
};

export type CouponConnectionId = {
  __typename?: 'CouponConnectionId';
  key?: Maybe<Scalars['ID']>;
  connection?: Maybe<CouponConnection>;
};

export type CouponConnectionName = {
  __typename?: 'CouponConnectionName';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<CouponConnection>;
};

export type CouponConnectionType = {
  __typename?: 'CouponConnectionType';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<CouponConnection>;
};

export type CouponConnectionUpdated_At = {
  __typename?: 'CouponConnectionUpdated_at';
  key?: Maybe<Scalars['DateTime']>;
  connection?: Maybe<CouponConnection>;
};

export type CouponGroupBy = {
  __typename?: 'CouponGroupBy';
  id?: Maybe<Array<Maybe<CouponConnectionId>>>;
  created_at?: Maybe<Array<Maybe<CouponConnectionCreated_At>>>;
  updated_at?: Maybe<Array<Maybe<CouponConnectionUpdated_At>>>;
  type?: Maybe<Array<Maybe<CouponConnectionType>>>;
  name?: Maybe<Array<Maybe<CouponConnectionName>>>;
  discount?: Maybe<Array<Maybe<CouponConnectionDiscount>>>;
  enabled?: Maybe<Array<Maybe<CouponConnectionEnabled>>>;
};

export type CouponInput = {
  type: Enum_Coupon_Type;
  name: Scalars['String'];
  discount: Scalars['Int'];
  enabled: Scalars['Boolean'];
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type DeliveryFee = {
  __typename?: 'DeliveryFee';
  id: Scalars['ID'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  origin?: Maybe<Scalars['String']>;
  delivery_fees?: Maybe<Array<Maybe<ComponentDeliveryFeeFee>>>;
};

export type DeliveryFeeInput = {
  origin?: Maybe<Scalars['String']>;
  delivery_fees?: Maybe<Array<Maybe<ComponentDeliveryFeeFeeInput>>>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type DeliveryMan = {
  __typename?: 'DeliveryMan';
  id: Scalars['ID'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  phone_number?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type DeliveryManAggregator = {
  __typename?: 'DeliveryManAggregator';
  count?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type DeliveryManConnection = {
  __typename?: 'DeliveryManConnection';
  values?: Maybe<Array<Maybe<DeliveryMan>>>;
  groupBy?: Maybe<DeliveryManGroupBy>;
  aggregate?: Maybe<DeliveryManAggregator>;
};

export type DeliveryManConnectionCreated_At = {
  __typename?: 'DeliveryManConnectionCreated_at';
  key?: Maybe<Scalars['DateTime']>;
  connection?: Maybe<DeliveryManConnection>;
};

export type DeliveryManConnectionEmail = {
  __typename?: 'DeliveryManConnectionEmail';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<DeliveryManConnection>;
};

export type DeliveryManConnectionId = {
  __typename?: 'DeliveryManConnectionId';
  key?: Maybe<Scalars['ID']>;
  connection?: Maybe<DeliveryManConnection>;
};

export type DeliveryManConnectionName = {
  __typename?: 'DeliveryManConnectionName';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<DeliveryManConnection>;
};

export type DeliveryManConnectionPhone_Number = {
  __typename?: 'DeliveryManConnectionPhone_number';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<DeliveryManConnection>;
};

export type DeliveryManConnectionUpdated_At = {
  __typename?: 'DeliveryManConnectionUpdated_at';
  key?: Maybe<Scalars['DateTime']>;
  connection?: Maybe<DeliveryManConnection>;
};

export type DeliveryManGroupBy = {
  __typename?: 'DeliveryManGroupBy';
  id?: Maybe<Array<Maybe<DeliveryManConnectionId>>>;
  created_at?: Maybe<Array<Maybe<DeliveryManConnectionCreated_At>>>;
  updated_at?: Maybe<Array<Maybe<DeliveryManConnectionUpdated_At>>>;
  phone_number?: Maybe<Array<Maybe<DeliveryManConnectionPhone_Number>>>;
  email?: Maybe<Array<Maybe<DeliveryManConnectionEmail>>>;
  name?: Maybe<Array<Maybe<DeliveryManConnectionName>>>;
};

export type DeliveryManInput = {
  phone_number?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export enum Enum_Coupon_Type {
  Cash = 'cash',
  Percentage = 'percentage',
  FreeDelivery = 'free_delivery'
}

export enum Enum_Orderitem_Unit_Type {
  Unit = 'unit',
  Weight = 'weight'
}

export enum Enum_Order_Order_Payment_Method {
  Cash = 'cash',
  Pix = 'pix',
  BankDeposit = 'bank_deposit',
  Debit = 'debit',
  Credit = 'credit',
  Ticket = 'ticket'
}

export enum Enum_Order_Order_Status {
  Requested = 'requested',
  Sent = 'sent',
  Delivered = 'delivered',
  Canceled = 'canceled'
}

export enum Enum_Userspermissionsuser_Gender {
  Male = 'male',
  Female = 'female',
  Other = 'other'
}

export type FileInfoInput = {
  name?: Maybe<Scalars['String']>;
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
};

export type FileInput = {
  name: Scalars['String'];
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  formats?: Maybe<Scalars['JSON']>;
  hash: Scalars['String'];
  ext?: Maybe<Scalars['String']>;
  mime: Scalars['String'];
  size: Scalars['Float'];
  url: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  provider_metadata?: Maybe<Scalars['JSON']>;
  related?: Maybe<Array<Maybe<Scalars['ID']>>>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type InputId = {
  id: Scalars['ID'];
};

export type Morph =
  | UsersPermissionsMe
  | UsersPermissionsMeRole
  | UsersPermissionsLoginPayload
  | UserPermissionsPasswordPayload
  | Coupon
  | CouponConnection
  | CouponAggregator
  | CouponAggregatorSum
  | CouponAggregatorAvg
  | CouponAggregatorMin
  | CouponAggregatorMax
  | CouponGroupBy
  | CouponConnectionId
  | CouponConnectionCreated_At
  | CouponConnectionUpdated_At
  | CouponConnectionType
  | CouponConnectionName
  | CouponConnectionDiscount
  | CouponConnectionEnabled
  | CreateCouponPayload
  | UpdateCouponPayload
  | DeleteCouponPayload
  | DeliveryFee
  | UpdateDeliveryFeePayload
  | DeleteDeliveryFeePayload
  | DeliveryMan
  | DeliveryManConnection
  | DeliveryManAggregator
  | DeliveryManGroupBy
  | DeliveryManConnectionId
  | DeliveryManConnectionCreated_At
  | DeliveryManConnectionUpdated_At
  | DeliveryManConnectionPhone_Number
  | DeliveryManConnectionEmail
  | DeliveryManConnectionName
  | CreateDeliveryManPayload
  | UpdateDeliveryManPayload
  | DeleteDeliveryManPayload
  | OrderItem
  | OrderItemConnection
  | OrderItemAggregator
  | OrderItemAggregatorSum
  | OrderItemAggregatorAvg
  | OrderItemAggregatorMin
  | OrderItemAggregatorMax
  | OrderItemGroupBy
  | OrderItemConnectionId
  | OrderItemConnectionCreated_At
  | OrderItemConnectionUpdated_At
  | OrderItemConnectionOrder
  | OrderItemConnectionQuantity
  | OrderItemConnectionProduct
  | OrderItemConnectionUnit_Type
  | OrderItemConnectionWeight_Purchase_Price
  | OrderItemConnectionUnit_Purchase_Price
  | OrderItemConnectionSale_Price
  | OrderItemConnectionDiscount_Enabled
  | OrderItemConnectionDetails
  | CreateOrderItemPayload
  | UpdateOrderItemPayload
  | DeleteOrderItemPayload
  | Order
  | OrderConnection
  | OrderAggregator
  | OrderAggregatorSum
  | OrderAggregatorAvg
  | OrderAggregatorMin
  | OrderAggregatorMax
  | OrderGroupBy
  | OrderConnectionId
  | OrderConnectionCreated_At
  | OrderConnectionUpdated_At
  | OrderConnectionCoupon
  | OrderConnectionSub_Total
  | OrderConnectionCoupon_Discount
  | OrderConnectionDelivery_Fee
  | OrderConnectionTotal
  | OrderConnectionDetails
  | OrderConnectionOrder_Status
  | OrderConnectionOrder_Payment_Method
  | OrderConnectionUser
  | OrderConnectionTake_Away
  | OrderConnectionDelivery_Man
  | OrderConnectionCancellation_Reason
  | CreateOrderPayload
  | UpdateOrderPayload
  | DeleteOrderPayload
  | ProductCategory
  | ProductCategoryConnection
  | ProductCategoryAggregator
  | ProductCategoryAggregatorSum
  | ProductCategoryAggregatorAvg
  | ProductCategoryAggregatorMin
  | ProductCategoryAggregatorMax
  | ProductCategoryGroupBy
  | ProductCategoryConnectionId
  | ProductCategoryConnectionCreated_At
  | ProductCategoryConnectionUpdated_At
  | ProductCategoryConnectionName
  | ProductCategoryConnectionSlug
  | ProductCategoryConnectionView_Order
  | CreateProductCategoryPayload
  | UpdateProductCategoryPayload
  | DeleteProductCategoryPayload
  | ProductHistory
  | ProductHistoryConnection
  | ProductHistoryAggregator
  | ProductHistoryAggregatorSum
  | ProductHistoryAggregatorAvg
  | ProductHistoryAggregatorMin
  | ProductHistoryAggregatorMax
  | ProductHistoryGroupBy
  | ProductHistoryConnectionId
  | ProductHistoryConnectionCreated_At
  | ProductHistoryConnectionUpdated_At
  | ProductHistoryConnectionProduct
  | ProductHistoryConnectionPurchase_Price
  | ProductHistoryConnectionSale_Price
  | ProductHistoryConnectionUser
  | CreateProductHistoryPayload
  | UpdateProductHistoryPayload
  | DeleteProductHistoryPayload
  | Product
  | ProductConnection
  | ProductAggregator
  | ProductAggregatorSum
  | ProductAggregatorAvg
  | ProductAggregatorMin
  | ProductAggregatorMax
  | ProductGroupBy
  | ProductConnectionId
  | ProductConnectionCreated_At
  | ProductConnectionUpdated_At
  | ProductConnectionName
  | ProductConnectionDescription
  | ProductConnectionImg
  | ProductConnectionPurchase_Price
  | ProductConnectionSale_Price
  | ProductConnectionEnabled
  | ProductConnectionDiscount_Price
  | ProductConnectionDiscount_Enabled
  | ProductConnectionUnit_Purchase_Price
  | ProductConnectionUnit_Sale_Price
  | ProductConnectionUnit_Weight
  | ProductConnectionUnit_Multiplus
  | ProductConnectionUnit_Discount_Price
  | ProductConnectionSale_By_Weight_Enabled
  | ProductConnectionSale_By_Unit_Enabled
  | ProductConnectionContact_Us_Enabled
  | CreateProductPayload
  | UpdateProductPayload
  | DeleteProductPayload
  | Supplier
  | SupplierConnection
  | SupplierAggregator
  | SupplierGroupBy
  | SupplierConnectionId
  | SupplierConnectionCreated_At
  | SupplierConnectionUpdated_At
  | SupplierConnectionName
  | SupplierConnectionPhone_Number
  | SupplierConnectionEmail
  | CreateSupplierPayload
  | UpdateSupplierPayload
  | DeleteSupplierPayload
  | Template
  | UpdateTemplatePayload
  | DeleteTemplatePayload
  | UploadFile
  | UploadFileConnection
  | UploadFileAggregator
  | UploadFileAggregatorSum
  | UploadFileAggregatorAvg
  | UploadFileAggregatorMin
  | UploadFileAggregatorMax
  | UploadFileGroupBy
  | UploadFileConnectionId
  | UploadFileConnectionCreated_At
  | UploadFileConnectionUpdated_At
  | UploadFileConnectionName
  | UploadFileConnectionAlternativeText
  | UploadFileConnectionCaption
  | UploadFileConnectionWidth
  | UploadFileConnectionHeight
  | UploadFileConnectionFormats
  | UploadFileConnectionHash
  | UploadFileConnectionExt
  | UploadFileConnectionMime
  | UploadFileConnectionSize
  | UploadFileConnectionUrl
  | UploadFileConnectionPreviewUrl
  | UploadFileConnectionProvider
  | UploadFileConnectionProvider_Metadata
  | DeleteFilePayload
  | UsersPermissionsPermission
  | UsersPermissionsRole
  | UsersPermissionsRoleConnection
  | UsersPermissionsRoleAggregator
  | UsersPermissionsRoleGroupBy
  | UsersPermissionsRoleConnectionId
  | UsersPermissionsRoleConnectionName
  | UsersPermissionsRoleConnectionDescription
  | UsersPermissionsRoleConnectionType
  | CreateRolePayload
  | UpdateRolePayload
  | DeleteRolePayload
  | UsersPermissionsUser
  | UsersPermissionsUserConnection
  | UsersPermissionsUserAggregator
  | UsersPermissionsUserGroupBy
  | UsersPermissionsUserConnectionId
  | UsersPermissionsUserConnectionCreated_At
  | UsersPermissionsUserConnectionUpdated_At
  | UsersPermissionsUserConnectionUsername
  | UsersPermissionsUserConnectionEmail
  | UsersPermissionsUserConnectionProvider
  | UsersPermissionsUserConnectionConfirmed
  | UsersPermissionsUserConnectionBlocked
  | UsersPermissionsUserConnectionRole
  | UsersPermissionsUserConnectionName
  | UsersPermissionsUserConnectionSurname
  | UsersPermissionsUserConnectionPhone_Number
  | UsersPermissionsUserConnectionBirth_Date
  | UsersPermissionsUserConnectionGender
  | UsersPermissionsUserConnectionZip_Code
  | UsersPermissionsUserConnectionStreet
  | UsersPermissionsUserConnectionHouse_Number
  | UsersPermissionsUserConnectionHouse_Reference
  | UsersPermissionsUserConnectionNeighborhood
  | UsersPermissionsUserConnectionCity
  | CreateUserPayload
  | UpdateUserPayload
  | DeleteUserPayload
  | ComponentDeliveryFeeFee;

export type Mutation = {
  __typename?: 'Mutation';
  createCoupon?: Maybe<CreateCouponPayload>;
  updateCoupon?: Maybe<UpdateCouponPayload>;
  deleteCoupon?: Maybe<DeleteCouponPayload>;
  updateDeliveryFee?: Maybe<UpdateDeliveryFeePayload>;
  deleteDeliveryFee?: Maybe<DeleteDeliveryFeePayload>;
  createDeliveryMan?: Maybe<CreateDeliveryManPayload>;
  updateDeliveryMan?: Maybe<UpdateDeliveryManPayload>;
  deleteDeliveryMan?: Maybe<DeleteDeliveryManPayload>;
  createOrderItem?: Maybe<CreateOrderItemPayload>;
  updateOrderItem?: Maybe<UpdateOrderItemPayload>;
  deleteOrderItem?: Maybe<DeleteOrderItemPayload>;
  createOrder?: Maybe<CreateOrderPayload>;
  updateOrder?: Maybe<UpdateOrderPayload>;
  deleteOrder?: Maybe<DeleteOrderPayload>;
  createProductCategory?: Maybe<CreateProductCategoryPayload>;
  updateProductCategory?: Maybe<UpdateProductCategoryPayload>;
  deleteProductCategory?: Maybe<DeleteProductCategoryPayload>;
  createProductHistory?: Maybe<CreateProductHistoryPayload>;
  updateProductHistory?: Maybe<UpdateProductHistoryPayload>;
  deleteProductHistory?: Maybe<DeleteProductHistoryPayload>;
  createProduct?: Maybe<CreateProductPayload>;
  updateProduct?: Maybe<UpdateProductPayload>;
  deleteProduct?: Maybe<DeleteProductPayload>;
  createSupplier?: Maybe<CreateSupplierPayload>;
  updateSupplier?: Maybe<UpdateSupplierPayload>;
  deleteSupplier?: Maybe<DeleteSupplierPayload>;
  updateTemplate?: Maybe<UpdateTemplatePayload>;
  deleteTemplate?: Maybe<DeleteTemplatePayload>;
  /** Delete one file */
  deleteFile?: Maybe<DeleteFilePayload>;
  /** Create a new role */
  createRole?: Maybe<CreateRolePayload>;
  /** Update an existing role */
  updateRole?: Maybe<UpdateRolePayload>;
  /** Delete an existing role */
  deleteRole?: Maybe<DeleteRolePayload>;
  /** Create a new user */
  createUser?: Maybe<CreateUserPayload>;
  /** Update an existing user */
  updateUser?: Maybe<UpdateUserPayload>;
  /** Delete an existing user */
  deleteUser?: Maybe<DeleteUserPayload>;
  upload: UploadFile;
  multipleUpload: Array<Maybe<UploadFile>>;
  updateFileInfo: UploadFile;
  login: UsersPermissionsLoginPayload;
  register: UsersPermissionsLoginPayload;
  forgotPassword?: Maybe<UserPermissionsPasswordPayload>;
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
};

export type MutationCreateCouponArgs = {
  input?: Maybe<CreateCouponInput>;
};

export type MutationUpdateCouponArgs = {
  input?: Maybe<UpdateCouponInput>;
};

export type MutationDeleteCouponArgs = {
  input?: Maybe<DeleteCouponInput>;
};

export type MutationUpdateDeliveryFeeArgs = {
  input?: Maybe<UpdateDeliveryFeeInput>;
};

export type MutationCreateDeliveryManArgs = {
  input?: Maybe<CreateDeliveryManInput>;
};

export type MutationUpdateDeliveryManArgs = {
  input?: Maybe<UpdateDeliveryManInput>;
};

export type MutationDeleteDeliveryManArgs = {
  input?: Maybe<DeleteDeliveryManInput>;
};

export type MutationCreateOrderItemArgs = {
  input?: Maybe<CreateOrderItemInput>;
};

export type MutationUpdateOrderItemArgs = {
  input?: Maybe<UpdateOrderItemInput>;
};

export type MutationDeleteOrderItemArgs = {
  input?: Maybe<DeleteOrderItemInput>;
};

export type MutationCreateOrderArgs = {
  input?: Maybe<CreateOrderInput>;
};

export type MutationUpdateOrderArgs = {
  input?: Maybe<UpdateOrderInput>;
};

export type MutationDeleteOrderArgs = {
  input?: Maybe<DeleteOrderInput>;
};

export type MutationCreateProductCategoryArgs = {
  input?: Maybe<CreateProductCategoryInput>;
};

export type MutationUpdateProductCategoryArgs = {
  input?: Maybe<UpdateProductCategoryInput>;
};

export type MutationDeleteProductCategoryArgs = {
  input?: Maybe<DeleteProductCategoryInput>;
};

export type MutationCreateProductHistoryArgs = {
  input?: Maybe<CreateProductHistoryInput>;
};

export type MutationUpdateProductHistoryArgs = {
  input?: Maybe<UpdateProductHistoryInput>;
};

export type MutationDeleteProductHistoryArgs = {
  input?: Maybe<DeleteProductHistoryInput>;
};

export type MutationCreateProductArgs = {
  input?: Maybe<CreateProductInput>;
};

export type MutationUpdateProductArgs = {
  input?: Maybe<UpdateProductInput>;
};

export type MutationDeleteProductArgs = {
  input?: Maybe<DeleteProductInput>;
};

export type MutationCreateSupplierArgs = {
  input?: Maybe<CreateSupplierInput>;
};

export type MutationUpdateSupplierArgs = {
  input?: Maybe<UpdateSupplierInput>;
};

export type MutationDeleteSupplierArgs = {
  input?: Maybe<DeleteSupplierInput>;
};

export type MutationUpdateTemplateArgs = {
  input?: Maybe<UpdateTemplateInput>;
};

export type MutationDeleteFileArgs = {
  input?: Maybe<DeleteFileInput>;
};

export type MutationCreateRoleArgs = {
  input?: Maybe<CreateRoleInput>;
};

export type MutationUpdateRoleArgs = {
  input?: Maybe<UpdateRoleInput>;
};

export type MutationDeleteRoleArgs = {
  input?: Maybe<DeleteRoleInput>;
};

export type MutationCreateUserArgs = {
  input?: Maybe<CreateUserInput>;
};

export type MutationUpdateUserArgs = {
  input?: Maybe<UpdateUserInput>;
};

export type MutationDeleteUserArgs = {
  input?: Maybe<DeleteUserInput>;
};

export type MutationUploadArgs = {
  refId?: Maybe<Scalars['ID']>;
  ref?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  info?: Maybe<FileInfoInput>;
  file: Scalars['Upload'];
};

export type MutationMultipleUploadArgs = {
  refId?: Maybe<Scalars['ID']>;
  ref?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  files: Array<Maybe<Scalars['Upload']>>;
};

export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID'];
  info: FileInfoInput;
};

export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};

export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};

export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};

export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  code: Scalars['String'];
};

export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  coupon?: Maybe<Coupon>;
  sub_total?: Maybe<Scalars['Float']>;
  coupon_discount?: Maybe<Scalars['Float']>;
  delivery_fee?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['String']>;
  order_status?: Maybe<Enum_Order_Order_Status>;
  order_payment_method?: Maybe<Enum_Order_Order_Payment_Method>;
  user?: Maybe<UsersPermissionsUser>;
  take_away?: Maybe<Scalars['Boolean']>;
  delivery_man?: Maybe<DeliveryMan>;
  cancellation_reason?: Maybe<Scalars['String']>;
  order_items?: Maybe<Array<Maybe<OrderItem>>>;
};

export type OrderOrder_ItemsArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};

export type OrderAggregator = {
  __typename?: 'OrderAggregator';
  count?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
  sum?: Maybe<OrderAggregatorSum>;
  avg?: Maybe<OrderAggregatorAvg>;
  min?: Maybe<OrderAggregatorMin>;
  max?: Maybe<OrderAggregatorMax>;
};

export type OrderAggregatorAvg = {
  __typename?: 'OrderAggregatorAvg';
  sub_total?: Maybe<Scalars['Float']>;
  coupon_discount?: Maybe<Scalars['Float']>;
  delivery_fee?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type OrderAggregatorMax = {
  __typename?: 'OrderAggregatorMax';
  sub_total?: Maybe<Scalars['Float']>;
  coupon_discount?: Maybe<Scalars['Float']>;
  delivery_fee?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type OrderAggregatorMin = {
  __typename?: 'OrderAggregatorMin';
  sub_total?: Maybe<Scalars['Float']>;
  coupon_discount?: Maybe<Scalars['Float']>;
  delivery_fee?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type OrderAggregatorSum = {
  __typename?: 'OrderAggregatorSum';
  sub_total?: Maybe<Scalars['Float']>;
  coupon_discount?: Maybe<Scalars['Float']>;
  delivery_fee?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  values?: Maybe<Array<Maybe<Order>>>;
  groupBy?: Maybe<OrderGroupBy>;
  aggregate?: Maybe<OrderAggregator>;
};

export type OrderConnectionCancellation_Reason = {
  __typename?: 'OrderConnectionCancellation_reason';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<OrderConnection>;
};

export type OrderConnectionCoupon = {
  __typename?: 'OrderConnectionCoupon';
  key?: Maybe<Scalars['ID']>;
  connection?: Maybe<OrderConnection>;
};

export type OrderConnectionCoupon_Discount = {
  __typename?: 'OrderConnectionCoupon_discount';
  key?: Maybe<Scalars['Float']>;
  connection?: Maybe<OrderConnection>;
};

export type OrderConnectionCreated_At = {
  __typename?: 'OrderConnectionCreated_at';
  key?: Maybe<Scalars['DateTime']>;
  connection?: Maybe<OrderConnection>;
};

export type OrderConnectionDelivery_Fee = {
  __typename?: 'OrderConnectionDelivery_fee';
  key?: Maybe<Scalars['Float']>;
  connection?: Maybe<OrderConnection>;
};

export type OrderConnectionDelivery_Man = {
  __typename?: 'OrderConnectionDelivery_man';
  key?: Maybe<Scalars['ID']>;
  connection?: Maybe<OrderConnection>;
};

export type OrderConnectionDetails = {
  __typename?: 'OrderConnectionDetails';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<OrderConnection>;
};

export type OrderConnectionId = {
  __typename?: 'OrderConnectionId';
  key?: Maybe<Scalars['ID']>;
  connection?: Maybe<OrderConnection>;
};

export type OrderConnectionOrder_Payment_Method = {
  __typename?: 'OrderConnectionOrder_payment_method';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<OrderConnection>;
};

export type OrderConnectionOrder_Status = {
  __typename?: 'OrderConnectionOrder_status';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<OrderConnection>;
};

export type OrderConnectionSub_Total = {
  __typename?: 'OrderConnectionSub_total';
  key?: Maybe<Scalars['Float']>;
  connection?: Maybe<OrderConnection>;
};

export type OrderConnectionTake_Away = {
  __typename?: 'OrderConnectionTake_away';
  key?: Maybe<Scalars['Boolean']>;
  connection?: Maybe<OrderConnection>;
};

export type OrderConnectionTotal = {
  __typename?: 'OrderConnectionTotal';
  key?: Maybe<Scalars['Float']>;
  connection?: Maybe<OrderConnection>;
};

export type OrderConnectionUpdated_At = {
  __typename?: 'OrderConnectionUpdated_at';
  key?: Maybe<Scalars['DateTime']>;
  connection?: Maybe<OrderConnection>;
};

export type OrderConnectionUser = {
  __typename?: 'OrderConnectionUser';
  key?: Maybe<Scalars['ID']>;
  connection?: Maybe<OrderConnection>;
};

export type OrderGroupBy = {
  __typename?: 'OrderGroupBy';
  id?: Maybe<Array<Maybe<OrderConnectionId>>>;
  created_at?: Maybe<Array<Maybe<OrderConnectionCreated_At>>>;
  updated_at?: Maybe<Array<Maybe<OrderConnectionUpdated_At>>>;
  coupon?: Maybe<Array<Maybe<OrderConnectionCoupon>>>;
  sub_total?: Maybe<Array<Maybe<OrderConnectionSub_Total>>>;
  coupon_discount?: Maybe<Array<Maybe<OrderConnectionCoupon_Discount>>>;
  delivery_fee?: Maybe<Array<Maybe<OrderConnectionDelivery_Fee>>>;
  total?: Maybe<Array<Maybe<OrderConnectionTotal>>>;
  details?: Maybe<Array<Maybe<OrderConnectionDetails>>>;
  order_status?: Maybe<Array<Maybe<OrderConnectionOrder_Status>>>;
  order_payment_method?: Maybe<Array<Maybe<OrderConnectionOrder_Payment_Method>>>;
  user?: Maybe<Array<Maybe<OrderConnectionUser>>>;
  take_away?: Maybe<Array<Maybe<OrderConnectionTake_Away>>>;
  delivery_man?: Maybe<Array<Maybe<OrderConnectionDelivery_Man>>>;
  cancellation_reason?: Maybe<Array<Maybe<OrderConnectionCancellation_Reason>>>;
};

export type OrderInput = {
  coupon?: Maybe<Scalars['ID']>;
  sub_total?: Maybe<Scalars['Float']>;
  coupon_discount?: Maybe<Scalars['Float']>;
  delivery_fee?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['String']>;
  order_items?: Maybe<Array<Maybe<Scalars['ID']>>>;
  order_status?: Maybe<Enum_Order_Order_Status>;
  order_payment_method?: Maybe<Enum_Order_Order_Payment_Method>;
  user?: Maybe<Scalars['ID']>;
  take_away?: Maybe<Scalars['Boolean']>;
  delivery_man?: Maybe<Scalars['ID']>;
  cancellation_reason?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  id: Scalars['ID'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  order?: Maybe<Order>;
  quantity?: Maybe<Scalars['Float']>;
  product?: Maybe<Product>;
  unit_type?: Maybe<Enum_Orderitem_Unit_Type>;
  weight_purchase_price?: Maybe<Scalars['Float']>;
  unit_purchase_price?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
  discount_enabled?: Maybe<Scalars['Boolean']>;
  details?: Maybe<Scalars['String']>;
};

export type OrderItemAggregator = {
  __typename?: 'OrderItemAggregator';
  count?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
  sum?: Maybe<OrderItemAggregatorSum>;
  avg?: Maybe<OrderItemAggregatorAvg>;
  min?: Maybe<OrderItemAggregatorMin>;
  max?: Maybe<OrderItemAggregatorMax>;
};

export type OrderItemAggregatorAvg = {
  __typename?: 'OrderItemAggregatorAvg';
  quantity?: Maybe<Scalars['Float']>;
  weight_purchase_price?: Maybe<Scalars['Float']>;
  unit_purchase_price?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
};

export type OrderItemAggregatorMax = {
  __typename?: 'OrderItemAggregatorMax';
  quantity?: Maybe<Scalars['Float']>;
  weight_purchase_price?: Maybe<Scalars['Float']>;
  unit_purchase_price?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
};

export type OrderItemAggregatorMin = {
  __typename?: 'OrderItemAggregatorMin';
  quantity?: Maybe<Scalars['Float']>;
  weight_purchase_price?: Maybe<Scalars['Float']>;
  unit_purchase_price?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
};

export type OrderItemAggregatorSum = {
  __typename?: 'OrderItemAggregatorSum';
  quantity?: Maybe<Scalars['Float']>;
  weight_purchase_price?: Maybe<Scalars['Float']>;
  unit_purchase_price?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
};

export type OrderItemConnection = {
  __typename?: 'OrderItemConnection';
  values?: Maybe<Array<Maybe<OrderItem>>>;
  groupBy?: Maybe<OrderItemGroupBy>;
  aggregate?: Maybe<OrderItemAggregator>;
};

export type OrderItemConnectionCreated_At = {
  __typename?: 'OrderItemConnectionCreated_at';
  key?: Maybe<Scalars['DateTime']>;
  connection?: Maybe<OrderItemConnection>;
};

export type OrderItemConnectionDetails = {
  __typename?: 'OrderItemConnectionDetails';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<OrderItemConnection>;
};

export type OrderItemConnectionDiscount_Enabled = {
  __typename?: 'OrderItemConnectionDiscount_enabled';
  key?: Maybe<Scalars['Boolean']>;
  connection?: Maybe<OrderItemConnection>;
};

export type OrderItemConnectionId = {
  __typename?: 'OrderItemConnectionId';
  key?: Maybe<Scalars['ID']>;
  connection?: Maybe<OrderItemConnection>;
};

export type OrderItemConnectionOrder = {
  __typename?: 'OrderItemConnectionOrder';
  key?: Maybe<Scalars['ID']>;
  connection?: Maybe<OrderItemConnection>;
};

export type OrderItemConnectionProduct = {
  __typename?: 'OrderItemConnectionProduct';
  key?: Maybe<Scalars['ID']>;
  connection?: Maybe<OrderItemConnection>;
};

export type OrderItemConnectionQuantity = {
  __typename?: 'OrderItemConnectionQuantity';
  key?: Maybe<Scalars['Float']>;
  connection?: Maybe<OrderItemConnection>;
};

export type OrderItemConnectionSale_Price = {
  __typename?: 'OrderItemConnectionSale_price';
  key?: Maybe<Scalars['Float']>;
  connection?: Maybe<OrderItemConnection>;
};

export type OrderItemConnectionUnit_Purchase_Price = {
  __typename?: 'OrderItemConnectionUnit_purchase_price';
  key?: Maybe<Scalars['Float']>;
  connection?: Maybe<OrderItemConnection>;
};

export type OrderItemConnectionUnit_Type = {
  __typename?: 'OrderItemConnectionUnit_type';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<OrderItemConnection>;
};

export type OrderItemConnectionUpdated_At = {
  __typename?: 'OrderItemConnectionUpdated_at';
  key?: Maybe<Scalars['DateTime']>;
  connection?: Maybe<OrderItemConnection>;
};

export type OrderItemConnectionWeight_Purchase_Price = {
  __typename?: 'OrderItemConnectionWeight_purchase_price';
  key?: Maybe<Scalars['Float']>;
  connection?: Maybe<OrderItemConnection>;
};

export type OrderItemGroupBy = {
  __typename?: 'OrderItemGroupBy';
  id?: Maybe<Array<Maybe<OrderItemConnectionId>>>;
  created_at?: Maybe<Array<Maybe<OrderItemConnectionCreated_At>>>;
  updated_at?: Maybe<Array<Maybe<OrderItemConnectionUpdated_At>>>;
  order?: Maybe<Array<Maybe<OrderItemConnectionOrder>>>;
  quantity?: Maybe<Array<Maybe<OrderItemConnectionQuantity>>>;
  product?: Maybe<Array<Maybe<OrderItemConnectionProduct>>>;
  unit_type?: Maybe<Array<Maybe<OrderItemConnectionUnit_Type>>>;
  weight_purchase_price?: Maybe<Array<Maybe<OrderItemConnectionWeight_Purchase_Price>>>;
  unit_purchase_price?: Maybe<Array<Maybe<OrderItemConnectionUnit_Purchase_Price>>>;
  sale_price?: Maybe<Array<Maybe<OrderItemConnectionSale_Price>>>;
  discount_enabled?: Maybe<Array<Maybe<OrderItemConnectionDiscount_Enabled>>>;
  details?: Maybe<Array<Maybe<OrderItemConnectionDetails>>>;
};

export type OrderItemInput = {
  order?: Maybe<Scalars['ID']>;
  quantity?: Maybe<Scalars['Float']>;
  product?: Maybe<Scalars['ID']>;
  unit_type?: Maybe<Enum_Orderitem_Unit_Type>;
  weight_purchase_price?: Maybe<Scalars['Float']>;
  unit_purchase_price?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
  discount_enabled?: Maybe<Scalars['Boolean']>;
  details?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  img?: Maybe<UploadFile>;
  purchase_price: Scalars['Float'];
  sale_price: Scalars['Float'];
  enabled: Scalars['Boolean'];
  discount_price?: Maybe<Scalars['Float']>;
  discount_enabled?: Maybe<Scalars['Boolean']>;
  unit_purchase_price?: Maybe<Scalars['Float']>;
  unit_sale_price?: Maybe<Scalars['Float']>;
  unit_weight?: Maybe<Scalars['Float']>;
  unit_multiplus?: Maybe<Scalars['Int']>;
  unit_discount_price?: Maybe<Scalars['Float']>;
  sale_by_weight_enabled: Scalars['Boolean'];
  sale_by_unit_enabled?: Maybe<Scalars['Boolean']>;
  contact_us_enabled?: Maybe<Scalars['Boolean']>;
  product_categories?: Maybe<Array<Maybe<ProductCategory>>>;
  suppliers?: Maybe<Array<Maybe<Supplier>>>;
};

export type ProductProduct_CategoriesArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};

export type ProductSuppliersArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};

export type ProductAggregator = {
  __typename?: 'ProductAggregator';
  count?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
  sum?: Maybe<ProductAggregatorSum>;
  avg?: Maybe<ProductAggregatorAvg>;
  min?: Maybe<ProductAggregatorMin>;
  max?: Maybe<ProductAggregatorMax>;
};

export type ProductAggregatorAvg = {
  __typename?: 'ProductAggregatorAvg';
  purchase_price?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
  discount_price?: Maybe<Scalars['Float']>;
  unit_purchase_price?: Maybe<Scalars['Float']>;
  unit_sale_price?: Maybe<Scalars['Float']>;
  unit_weight?: Maybe<Scalars['Float']>;
  unit_multiplus?: Maybe<Scalars['Float']>;
  unit_discount_price?: Maybe<Scalars['Float']>;
};

export type ProductAggregatorMax = {
  __typename?: 'ProductAggregatorMax';
  purchase_price?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
  discount_price?: Maybe<Scalars['Float']>;
  unit_purchase_price?: Maybe<Scalars['Float']>;
  unit_sale_price?: Maybe<Scalars['Float']>;
  unit_weight?: Maybe<Scalars['Float']>;
  unit_multiplus?: Maybe<Scalars['Float']>;
  unit_discount_price?: Maybe<Scalars['Float']>;
};

export type ProductAggregatorMin = {
  __typename?: 'ProductAggregatorMin';
  purchase_price?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
  discount_price?: Maybe<Scalars['Float']>;
  unit_purchase_price?: Maybe<Scalars['Float']>;
  unit_sale_price?: Maybe<Scalars['Float']>;
  unit_weight?: Maybe<Scalars['Float']>;
  unit_multiplus?: Maybe<Scalars['Float']>;
  unit_discount_price?: Maybe<Scalars['Float']>;
};

export type ProductAggregatorSum = {
  __typename?: 'ProductAggregatorSum';
  purchase_price?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
  discount_price?: Maybe<Scalars['Float']>;
  unit_purchase_price?: Maybe<Scalars['Float']>;
  unit_sale_price?: Maybe<Scalars['Float']>;
  unit_weight?: Maybe<Scalars['Float']>;
  unit_multiplus?: Maybe<Scalars['Float']>;
  unit_discount_price?: Maybe<Scalars['Float']>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  id: Scalars['ID'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  view_order?: Maybe<Scalars['Int']>;
};

export type ProductCategoryAggregator = {
  __typename?: 'ProductCategoryAggregator';
  count?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
  sum?: Maybe<ProductCategoryAggregatorSum>;
  avg?: Maybe<ProductCategoryAggregatorAvg>;
  min?: Maybe<ProductCategoryAggregatorMin>;
  max?: Maybe<ProductCategoryAggregatorMax>;
};

export type ProductCategoryAggregatorAvg = {
  __typename?: 'ProductCategoryAggregatorAvg';
  view_order?: Maybe<Scalars['Float']>;
};

export type ProductCategoryAggregatorMax = {
  __typename?: 'ProductCategoryAggregatorMax';
  view_order?: Maybe<Scalars['Float']>;
};

export type ProductCategoryAggregatorMin = {
  __typename?: 'ProductCategoryAggregatorMin';
  view_order?: Maybe<Scalars['Float']>;
};

export type ProductCategoryAggregatorSum = {
  __typename?: 'ProductCategoryAggregatorSum';
  view_order?: Maybe<Scalars['Float']>;
};

export type ProductCategoryConnection = {
  __typename?: 'ProductCategoryConnection';
  values?: Maybe<Array<Maybe<ProductCategory>>>;
  groupBy?: Maybe<ProductCategoryGroupBy>;
  aggregate?: Maybe<ProductCategoryAggregator>;
};

export type ProductCategoryConnectionCreated_At = {
  __typename?: 'ProductCategoryConnectionCreated_at';
  key?: Maybe<Scalars['DateTime']>;
  connection?: Maybe<ProductCategoryConnection>;
};

export type ProductCategoryConnectionId = {
  __typename?: 'ProductCategoryConnectionId';
  key?: Maybe<Scalars['ID']>;
  connection?: Maybe<ProductCategoryConnection>;
};

export type ProductCategoryConnectionName = {
  __typename?: 'ProductCategoryConnectionName';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<ProductCategoryConnection>;
};

export type ProductCategoryConnectionSlug = {
  __typename?: 'ProductCategoryConnectionSlug';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<ProductCategoryConnection>;
};

export type ProductCategoryConnectionUpdated_At = {
  __typename?: 'ProductCategoryConnectionUpdated_at';
  key?: Maybe<Scalars['DateTime']>;
  connection?: Maybe<ProductCategoryConnection>;
};

export type ProductCategoryConnectionView_Order = {
  __typename?: 'ProductCategoryConnectionView_order';
  key?: Maybe<Scalars['Int']>;
  connection?: Maybe<ProductCategoryConnection>;
};

export type ProductCategoryGroupBy = {
  __typename?: 'ProductCategoryGroupBy';
  id?: Maybe<Array<Maybe<ProductCategoryConnectionId>>>;
  created_at?: Maybe<Array<Maybe<ProductCategoryConnectionCreated_At>>>;
  updated_at?: Maybe<Array<Maybe<ProductCategoryConnectionUpdated_At>>>;
  name?: Maybe<Array<Maybe<ProductCategoryConnectionName>>>;
  slug?: Maybe<Array<Maybe<ProductCategoryConnectionSlug>>>;
  view_order?: Maybe<Array<Maybe<ProductCategoryConnectionView_Order>>>;
};

export type ProductCategoryInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  view_order?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type ProductConnection = {
  __typename?: 'ProductConnection';
  values?: Maybe<Array<Maybe<Product>>>;
  groupBy?: Maybe<ProductGroupBy>;
  aggregate?: Maybe<ProductAggregator>;
};

export type ProductConnectionContact_Us_Enabled = {
  __typename?: 'ProductConnectionContact_us_enabled';
  key?: Maybe<Scalars['Boolean']>;
  connection?: Maybe<ProductConnection>;
};

export type ProductConnectionCreated_At = {
  __typename?: 'ProductConnectionCreated_at';
  key?: Maybe<Scalars['DateTime']>;
  connection?: Maybe<ProductConnection>;
};

export type ProductConnectionDescription = {
  __typename?: 'ProductConnectionDescription';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<ProductConnection>;
};

export type ProductConnectionDiscount_Enabled = {
  __typename?: 'ProductConnectionDiscount_enabled';
  key?: Maybe<Scalars['Boolean']>;
  connection?: Maybe<ProductConnection>;
};

export type ProductConnectionDiscount_Price = {
  __typename?: 'ProductConnectionDiscount_price';
  key?: Maybe<Scalars['Float']>;
  connection?: Maybe<ProductConnection>;
};

export type ProductConnectionEnabled = {
  __typename?: 'ProductConnectionEnabled';
  key?: Maybe<Scalars['Boolean']>;
  connection?: Maybe<ProductConnection>;
};

export type ProductConnectionId = {
  __typename?: 'ProductConnectionId';
  key?: Maybe<Scalars['ID']>;
  connection?: Maybe<ProductConnection>;
};

export type ProductConnectionImg = {
  __typename?: 'ProductConnectionImg';
  key?: Maybe<Scalars['ID']>;
  connection?: Maybe<ProductConnection>;
};

export type ProductConnectionName = {
  __typename?: 'ProductConnectionName';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<ProductConnection>;
};

export type ProductConnectionPurchase_Price = {
  __typename?: 'ProductConnectionPurchase_price';
  key?: Maybe<Scalars['Float']>;
  connection?: Maybe<ProductConnection>;
};

export type ProductConnectionSale_By_Unit_Enabled = {
  __typename?: 'ProductConnectionSale_by_unit_enabled';
  key?: Maybe<Scalars['Boolean']>;
  connection?: Maybe<ProductConnection>;
};

export type ProductConnectionSale_By_Weight_Enabled = {
  __typename?: 'ProductConnectionSale_by_weight_enabled';
  key?: Maybe<Scalars['Boolean']>;
  connection?: Maybe<ProductConnection>;
};

export type ProductConnectionSale_Price = {
  __typename?: 'ProductConnectionSale_price';
  key?: Maybe<Scalars['Float']>;
  connection?: Maybe<ProductConnection>;
};

export type ProductConnectionUnit_Discount_Price = {
  __typename?: 'ProductConnectionUnit_discount_price';
  key?: Maybe<Scalars['Float']>;
  connection?: Maybe<ProductConnection>;
};

export type ProductConnectionUnit_Multiplus = {
  __typename?: 'ProductConnectionUnit_multiplus';
  key?: Maybe<Scalars['Int']>;
  connection?: Maybe<ProductConnection>;
};

export type ProductConnectionUnit_Purchase_Price = {
  __typename?: 'ProductConnectionUnit_purchase_price';
  key?: Maybe<Scalars['Float']>;
  connection?: Maybe<ProductConnection>;
};

export type ProductConnectionUnit_Sale_Price = {
  __typename?: 'ProductConnectionUnit_sale_price';
  key?: Maybe<Scalars['Float']>;
  connection?: Maybe<ProductConnection>;
};

export type ProductConnectionUnit_Weight = {
  __typename?: 'ProductConnectionUnit_weight';
  key?: Maybe<Scalars['Float']>;
  connection?: Maybe<ProductConnection>;
};

export type ProductConnectionUpdated_At = {
  __typename?: 'ProductConnectionUpdated_at';
  key?: Maybe<Scalars['DateTime']>;
  connection?: Maybe<ProductConnection>;
};

export type ProductGroupBy = {
  __typename?: 'ProductGroupBy';
  id?: Maybe<Array<Maybe<ProductConnectionId>>>;
  created_at?: Maybe<Array<Maybe<ProductConnectionCreated_At>>>;
  updated_at?: Maybe<Array<Maybe<ProductConnectionUpdated_At>>>;
  name?: Maybe<Array<Maybe<ProductConnectionName>>>;
  description?: Maybe<Array<Maybe<ProductConnectionDescription>>>;
  img?: Maybe<Array<Maybe<ProductConnectionImg>>>;
  purchase_price?: Maybe<Array<Maybe<ProductConnectionPurchase_Price>>>;
  sale_price?: Maybe<Array<Maybe<ProductConnectionSale_Price>>>;
  enabled?: Maybe<Array<Maybe<ProductConnectionEnabled>>>;
  discount_price?: Maybe<Array<Maybe<ProductConnectionDiscount_Price>>>;
  discount_enabled?: Maybe<Array<Maybe<ProductConnectionDiscount_Enabled>>>;
  unit_purchase_price?: Maybe<Array<Maybe<ProductConnectionUnit_Purchase_Price>>>;
  unit_sale_price?: Maybe<Array<Maybe<ProductConnectionUnit_Sale_Price>>>;
  unit_weight?: Maybe<Array<Maybe<ProductConnectionUnit_Weight>>>;
  unit_multiplus?: Maybe<Array<Maybe<ProductConnectionUnit_Multiplus>>>;
  unit_discount_price?: Maybe<Array<Maybe<ProductConnectionUnit_Discount_Price>>>;
  sale_by_weight_enabled?: Maybe<Array<Maybe<ProductConnectionSale_By_Weight_Enabled>>>;
  sale_by_unit_enabled?: Maybe<Array<Maybe<ProductConnectionSale_By_Unit_Enabled>>>;
  contact_us_enabled?: Maybe<Array<Maybe<ProductConnectionContact_Us_Enabled>>>;
};

export type ProductHistory = {
  __typename?: 'ProductHistory';
  id: Scalars['ID'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  product?: Maybe<Product>;
  purchase_price?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
  user?: Maybe<UsersPermissionsUser>;
};

export type ProductHistoryAggregator = {
  __typename?: 'ProductHistoryAggregator';
  count?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
  sum?: Maybe<ProductHistoryAggregatorSum>;
  avg?: Maybe<ProductHistoryAggregatorAvg>;
  min?: Maybe<ProductHistoryAggregatorMin>;
  max?: Maybe<ProductHistoryAggregatorMax>;
};

export type ProductHistoryAggregatorAvg = {
  __typename?: 'ProductHistoryAggregatorAvg';
  purchase_price?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
};

export type ProductHistoryAggregatorMax = {
  __typename?: 'ProductHistoryAggregatorMax';
  purchase_price?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
};

export type ProductHistoryAggregatorMin = {
  __typename?: 'ProductHistoryAggregatorMin';
  purchase_price?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
};

export type ProductHistoryAggregatorSum = {
  __typename?: 'ProductHistoryAggregatorSum';
  purchase_price?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
};

export type ProductHistoryConnection = {
  __typename?: 'ProductHistoryConnection';
  values?: Maybe<Array<Maybe<ProductHistory>>>;
  groupBy?: Maybe<ProductHistoryGroupBy>;
  aggregate?: Maybe<ProductHistoryAggregator>;
};

export type ProductHistoryConnectionCreated_At = {
  __typename?: 'ProductHistoryConnectionCreated_at';
  key?: Maybe<Scalars['DateTime']>;
  connection?: Maybe<ProductHistoryConnection>;
};

export type ProductHistoryConnectionId = {
  __typename?: 'ProductHistoryConnectionId';
  key?: Maybe<Scalars['ID']>;
  connection?: Maybe<ProductHistoryConnection>;
};

export type ProductHistoryConnectionProduct = {
  __typename?: 'ProductHistoryConnectionProduct';
  key?: Maybe<Scalars['ID']>;
  connection?: Maybe<ProductHistoryConnection>;
};

export type ProductHistoryConnectionPurchase_Price = {
  __typename?: 'ProductHistoryConnectionPurchase_price';
  key?: Maybe<Scalars['Float']>;
  connection?: Maybe<ProductHistoryConnection>;
};

export type ProductHistoryConnectionSale_Price = {
  __typename?: 'ProductHistoryConnectionSale_price';
  key?: Maybe<Scalars['Float']>;
  connection?: Maybe<ProductHistoryConnection>;
};

export type ProductHistoryConnectionUpdated_At = {
  __typename?: 'ProductHistoryConnectionUpdated_at';
  key?: Maybe<Scalars['DateTime']>;
  connection?: Maybe<ProductHistoryConnection>;
};

export type ProductHistoryConnectionUser = {
  __typename?: 'ProductHistoryConnectionUser';
  key?: Maybe<Scalars['ID']>;
  connection?: Maybe<ProductHistoryConnection>;
};

export type ProductHistoryGroupBy = {
  __typename?: 'ProductHistoryGroupBy';
  id?: Maybe<Array<Maybe<ProductHistoryConnectionId>>>;
  created_at?: Maybe<Array<Maybe<ProductHistoryConnectionCreated_At>>>;
  updated_at?: Maybe<Array<Maybe<ProductHistoryConnectionUpdated_At>>>;
  product?: Maybe<Array<Maybe<ProductHistoryConnectionProduct>>>;
  purchase_price?: Maybe<Array<Maybe<ProductHistoryConnectionPurchase_Price>>>;
  sale_price?: Maybe<Array<Maybe<ProductHistoryConnectionSale_Price>>>;
  user?: Maybe<Array<Maybe<ProductHistoryConnectionUser>>>;
};

export type ProductHistoryInput = {
  product?: Maybe<Scalars['ID']>;
  purchase_price?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
  user?: Maybe<Scalars['ID']>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type ProductInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  img?: Maybe<Scalars['ID']>;
  purchase_price?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
  enabled: Scalars['Boolean'];
  discount_price?: Maybe<Scalars['Float']>;
  discount_enabled?: Maybe<Scalars['Boolean']>;
  product_categories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  suppliers?: Maybe<Array<Maybe<Scalars['ID']>>>;
  unit_purchase_price?: Maybe<Scalars['Float']>;
  unit_sale_price?: Maybe<Scalars['Float']>;
  unit_weight?: Maybe<Scalars['Float']>;
  unit_multiplus?: Maybe<Scalars['Int']>;
  unit_discount_price?: Maybe<Scalars['Float']>;
  sale_by_weight_enabled: Scalars['Boolean'];
  sale_by_unit_enabled?: Maybe<Scalars['Boolean']>;
  contact_us_enabled?: Maybe<Scalars['Boolean']>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW'
}

export type Query = {
  __typename?: 'Query';
  coupon?: Maybe<Coupon>;
  coupons?: Maybe<Array<Maybe<Coupon>>>;
  couponsConnection?: Maybe<CouponConnection>;
  deliveryFee?: Maybe<DeliveryFee>;
  deliveryMan?: Maybe<DeliveryMan>;
  deliveryMen?: Maybe<Array<Maybe<DeliveryMan>>>;
  deliveryMenConnection?: Maybe<DeliveryManConnection>;
  orderItem?: Maybe<OrderItem>;
  orderItems?: Maybe<Array<Maybe<OrderItem>>>;
  orderItemsConnection?: Maybe<OrderItemConnection>;
  order?: Maybe<Order>;
  orders?: Maybe<Array<Maybe<Order>>>;
  ordersConnection?: Maybe<OrderConnection>;
  productCategory?: Maybe<ProductCategory>;
  productCategories?: Maybe<Array<Maybe<ProductCategory>>>;
  productCategoriesConnection?: Maybe<ProductCategoryConnection>;
  productHistory?: Maybe<ProductHistory>;
  productHistories?: Maybe<Array<Maybe<ProductHistory>>>;
  productHistoriesConnection?: Maybe<ProductHistoryConnection>;
  product?: Maybe<Product>;
  products?: Maybe<Array<Maybe<Product>>>;
  productsConnection?: Maybe<ProductConnection>;
  supplier?: Maybe<Supplier>;
  suppliers?: Maybe<Array<Maybe<Supplier>>>;
  suppliersConnection?: Maybe<SupplierConnection>;
  template?: Maybe<Template>;
  files?: Maybe<Array<Maybe<UploadFile>>>;
  filesConnection?: Maybe<UploadFileConnection>;
  role?: Maybe<UsersPermissionsRole>;
  /** Retrieve all the existing roles. You can't apply filters on this query. */
  roles?: Maybe<Array<Maybe<UsersPermissionsRole>>>;
  rolesConnection?: Maybe<UsersPermissionsRoleConnection>;
  user?: Maybe<UsersPermissionsUser>;
  users?: Maybe<Array<Maybe<UsersPermissionsUser>>>;
  usersConnection?: Maybe<UsersPermissionsUserConnection>;
  me?: Maybe<UsersPermissionsMe>;
  calculate?: Maybe<ComponentDeliveryFeeFee>;
  myOrders?: Maybe<Array<Maybe<Order>>>;
};

export type QueryCouponArgs = {
  id: Scalars['ID'];
  publicationState?: Maybe<PublicationState>;
};

export type QueryCouponsArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
  publicationState?: Maybe<PublicationState>;
};

export type QueryCouponsConnectionArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};

export type QueryDeliveryFeeArgs = {
  publicationState?: Maybe<PublicationState>;
};

export type QueryDeliveryManArgs = {
  id: Scalars['ID'];
  publicationState?: Maybe<PublicationState>;
};

export type QueryDeliveryMenArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
  publicationState?: Maybe<PublicationState>;
};

export type QueryDeliveryMenConnectionArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};

export type QueryOrderItemArgs = {
  id: Scalars['ID'];
  publicationState?: Maybe<PublicationState>;
};

export type QueryOrderItemsArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
  publicationState?: Maybe<PublicationState>;
};

export type QueryOrderItemsConnectionArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};

export type QueryOrderArgs = {
  id: Scalars['ID'];
  publicationState?: Maybe<PublicationState>;
};

export type QueryOrdersArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
  publicationState?: Maybe<PublicationState>;
};

export type QueryOrdersConnectionArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};

export type QueryProductCategoryArgs = {
  id: Scalars['ID'];
  publicationState?: Maybe<PublicationState>;
};

export type QueryProductCategoriesArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
  publicationState?: Maybe<PublicationState>;
};

export type QueryProductCategoriesConnectionArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};

export type QueryProductHistoryArgs = {
  id: Scalars['ID'];
  publicationState?: Maybe<PublicationState>;
};

export type QueryProductHistoriesArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
  publicationState?: Maybe<PublicationState>;
};

export type QueryProductHistoriesConnectionArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};

export type QueryProductArgs = {
  id: Scalars['ID'];
  publicationState?: Maybe<PublicationState>;
};

export type QueryProductsArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
  publicationState?: Maybe<PublicationState>;
};

export type QueryProductsConnectionArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};

export type QuerySupplierArgs = {
  id: Scalars['ID'];
  publicationState?: Maybe<PublicationState>;
};

export type QuerySuppliersArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
  publicationState?: Maybe<PublicationState>;
};

export type QuerySuppliersConnectionArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};

export type QueryTemplateArgs = {
  publicationState?: Maybe<PublicationState>;
};

export type QueryFilesArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
  publicationState?: Maybe<PublicationState>;
};

export type QueryFilesConnectionArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};

export type QueryRoleArgs = {
  id: Scalars['ID'];
  publicationState?: Maybe<PublicationState>;
};

export type QueryRolesArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
  publicationState?: Maybe<PublicationState>;
};

export type QueryRolesConnectionArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
  publicationState?: Maybe<PublicationState>;
};

export type QueryUsersArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
  publicationState?: Maybe<PublicationState>;
};

export type QueryUsersConnectionArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};

export type QueryCalculateArgs = {
  subtotal: Scalars['String'];
  couponType?: Maybe<Enum_Coupon_Type>;
};

export type RoleInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  users?: Maybe<Array<Maybe<Scalars['ID']>>>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type Supplier = {
  __typename?: 'Supplier';
  id: Scalars['ID'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type SupplierAggregator = {
  __typename?: 'SupplierAggregator';
  count?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SupplierConnection = {
  __typename?: 'SupplierConnection';
  values?: Maybe<Array<Maybe<Supplier>>>;
  groupBy?: Maybe<SupplierGroupBy>;
  aggregate?: Maybe<SupplierAggregator>;
};

export type SupplierConnectionCreated_At = {
  __typename?: 'SupplierConnectionCreated_at';
  key?: Maybe<Scalars['DateTime']>;
  connection?: Maybe<SupplierConnection>;
};

export type SupplierConnectionEmail = {
  __typename?: 'SupplierConnectionEmail';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<SupplierConnection>;
};

export type SupplierConnectionId = {
  __typename?: 'SupplierConnectionId';
  key?: Maybe<Scalars['ID']>;
  connection?: Maybe<SupplierConnection>;
};

export type SupplierConnectionName = {
  __typename?: 'SupplierConnectionName';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<SupplierConnection>;
};

export type SupplierConnectionPhone_Number = {
  __typename?: 'SupplierConnectionPhone_number';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<SupplierConnection>;
};

export type SupplierConnectionUpdated_At = {
  __typename?: 'SupplierConnectionUpdated_at';
  key?: Maybe<Scalars['DateTime']>;
  connection?: Maybe<SupplierConnection>;
};

export type SupplierGroupBy = {
  __typename?: 'SupplierGroupBy';
  id?: Maybe<Array<Maybe<SupplierConnectionId>>>;
  created_at?: Maybe<Array<Maybe<SupplierConnectionCreated_At>>>;
  updated_at?: Maybe<Array<Maybe<SupplierConnectionUpdated_At>>>;
  name?: Maybe<Array<Maybe<SupplierConnectionName>>>;
  phone_number?: Maybe<Array<Maybe<SupplierConnectionPhone_Number>>>;
  email?: Maybe<Array<Maybe<SupplierConnectionEmail>>>;
};

export type SupplierInput = {
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type Template = {
  __typename?: 'Template';
  id: Scalars['ID'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  banner?: Maybe<UploadFile>;
};

export type TemplateInput = {
  banner?: Maybe<Scalars['ID']>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type UploadFile = {
  __typename?: 'UploadFile';
  id: Scalars['ID'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  name: Scalars['String'];
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  formats?: Maybe<Scalars['JSON']>;
  hash: Scalars['String'];
  ext?: Maybe<Scalars['String']>;
  mime: Scalars['String'];
  size: Scalars['Float'];
  url: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  provider_metadata?: Maybe<Scalars['JSON']>;
  related?: Maybe<Array<Maybe<Morph>>>;
};

export type UploadFileRelatedArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};

export type UploadFileAggregator = {
  __typename?: 'UploadFileAggregator';
  count?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
  sum?: Maybe<UploadFileAggregatorSum>;
  avg?: Maybe<UploadFileAggregatorAvg>;
  min?: Maybe<UploadFileAggregatorMin>;
  max?: Maybe<UploadFileAggregatorMax>;
};

export type UploadFileAggregatorAvg = {
  __typename?: 'UploadFileAggregatorAvg';
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

export type UploadFileAggregatorMax = {
  __typename?: 'UploadFileAggregatorMax';
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

export type UploadFileAggregatorMin = {
  __typename?: 'UploadFileAggregatorMin';
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

export type UploadFileAggregatorSum = {
  __typename?: 'UploadFileAggregatorSum';
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

export type UploadFileConnection = {
  __typename?: 'UploadFileConnection';
  values?: Maybe<Array<Maybe<UploadFile>>>;
  groupBy?: Maybe<UploadFileGroupBy>;
  aggregate?: Maybe<UploadFileAggregator>;
};

export type UploadFileConnectionAlternativeText = {
  __typename?: 'UploadFileConnectionAlternativeText';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionCaption = {
  __typename?: 'UploadFileConnectionCaption';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionCreated_At = {
  __typename?: 'UploadFileConnectionCreated_at';
  key?: Maybe<Scalars['DateTime']>;
  connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionExt = {
  __typename?: 'UploadFileConnectionExt';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionFormats = {
  __typename?: 'UploadFileConnectionFormats';
  key?: Maybe<Scalars['JSON']>;
  connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionHash = {
  __typename?: 'UploadFileConnectionHash';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionHeight = {
  __typename?: 'UploadFileConnectionHeight';
  key?: Maybe<Scalars['Int']>;
  connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionId = {
  __typename?: 'UploadFileConnectionId';
  key?: Maybe<Scalars['ID']>;
  connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionMime = {
  __typename?: 'UploadFileConnectionMime';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionName = {
  __typename?: 'UploadFileConnectionName';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionPreviewUrl = {
  __typename?: 'UploadFileConnectionPreviewUrl';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionProvider = {
  __typename?: 'UploadFileConnectionProvider';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionProvider_Metadata = {
  __typename?: 'UploadFileConnectionProvider_metadata';
  key?: Maybe<Scalars['JSON']>;
  connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionSize = {
  __typename?: 'UploadFileConnectionSize';
  key?: Maybe<Scalars['Float']>;
  connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionUpdated_At = {
  __typename?: 'UploadFileConnectionUpdated_at';
  key?: Maybe<Scalars['DateTime']>;
  connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionUrl = {
  __typename?: 'UploadFileConnectionUrl';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionWidth = {
  __typename?: 'UploadFileConnectionWidth';
  key?: Maybe<Scalars['Int']>;
  connection?: Maybe<UploadFileConnection>;
};

export type UploadFileGroupBy = {
  __typename?: 'UploadFileGroupBy';
  id?: Maybe<Array<Maybe<UploadFileConnectionId>>>;
  created_at?: Maybe<Array<Maybe<UploadFileConnectionCreated_At>>>;
  updated_at?: Maybe<Array<Maybe<UploadFileConnectionUpdated_At>>>;
  name?: Maybe<Array<Maybe<UploadFileConnectionName>>>;
  alternativeText?: Maybe<Array<Maybe<UploadFileConnectionAlternativeText>>>;
  caption?: Maybe<Array<Maybe<UploadFileConnectionCaption>>>;
  width?: Maybe<Array<Maybe<UploadFileConnectionWidth>>>;
  height?: Maybe<Array<Maybe<UploadFileConnectionHeight>>>;
  formats?: Maybe<Array<Maybe<UploadFileConnectionFormats>>>;
  hash?: Maybe<Array<Maybe<UploadFileConnectionHash>>>;
  ext?: Maybe<Array<Maybe<UploadFileConnectionExt>>>;
  mime?: Maybe<Array<Maybe<UploadFileConnectionMime>>>;
  size?: Maybe<Array<Maybe<UploadFileConnectionSize>>>;
  url?: Maybe<Array<Maybe<UploadFileConnectionUrl>>>;
  previewUrl?: Maybe<Array<Maybe<UploadFileConnectionPreviewUrl>>>;
  provider?: Maybe<Array<Maybe<UploadFileConnectionProvider>>>;
  provider_metadata?: Maybe<Array<Maybe<UploadFileConnectionProvider_Metadata>>>;
};

export type UserInput = {
  username: Scalars['String'];
  email: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  resetPasswordToken?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  blocked?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['String']>;
  gender?: Maybe<Enum_Userspermissionsuser_Gender>;
  zip_code?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  house_number?: Maybe<Scalars['String']>;
  house_reference?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Maybe<Scalars['ID']>>>;
  confirmationToken?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type UserPermissionsPasswordPayload = {
  __typename?: 'UserPermissionsPasswordPayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String'];
  password: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  id: Scalars['ID'];
  username: Scalars['String'];
  email: Scalars['String'];
  confirmed?: Maybe<Scalars['Boolean']>;
  blocked?: Maybe<Scalars['Boolean']>;
  role?: Maybe<UsersPermissionsMeRole>;
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  id: Scalars['ID'];
  type: Scalars['String'];
  controller: Scalars['String'];
  action: Scalars['String'];
  enabled: Scalars['Boolean'];
  policy?: Maybe<Scalars['String']>;
  role?: Maybe<UsersPermissionsRole>;
};

export type UsersPermissionsRegisterInput = {
  username: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<UsersPermissionsPermission>>>;
  users?: Maybe<Array<Maybe<UsersPermissionsUser>>>;
};

export type UsersPermissionsRolePermissionsArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};

export type UsersPermissionsRoleUsersArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};

export type UsersPermissionsRoleAggregator = {
  __typename?: 'UsersPermissionsRoleAggregator';
  count?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type UsersPermissionsRoleConnection = {
  __typename?: 'UsersPermissionsRoleConnection';
  values?: Maybe<Array<Maybe<UsersPermissionsRole>>>;
  groupBy?: Maybe<UsersPermissionsRoleGroupBy>;
  aggregate?: Maybe<UsersPermissionsRoleAggregator>;
};

export type UsersPermissionsRoleConnectionDescription = {
  __typename?: 'UsersPermissionsRoleConnectionDescription';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UsersPermissionsRoleConnection>;
};

export type UsersPermissionsRoleConnectionId = {
  __typename?: 'UsersPermissionsRoleConnectionId';
  key?: Maybe<Scalars['ID']>;
  connection?: Maybe<UsersPermissionsRoleConnection>;
};

export type UsersPermissionsRoleConnectionName = {
  __typename?: 'UsersPermissionsRoleConnectionName';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UsersPermissionsRoleConnection>;
};

export type UsersPermissionsRoleConnectionType = {
  __typename?: 'UsersPermissionsRoleConnectionType';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UsersPermissionsRoleConnection>;
};

export type UsersPermissionsRoleGroupBy = {
  __typename?: 'UsersPermissionsRoleGroupBy';
  id?: Maybe<Array<Maybe<UsersPermissionsRoleConnectionId>>>;
  name?: Maybe<Array<Maybe<UsersPermissionsRoleConnectionName>>>;
  description?: Maybe<Array<Maybe<UsersPermissionsRoleConnectionDescription>>>;
  type?: Maybe<Array<Maybe<UsersPermissionsRoleConnectionType>>>;
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  id: Scalars['ID'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  username: Scalars['String'];
  email: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  blocked?: Maybe<Scalars['Boolean']>;
  role?: Maybe<UsersPermissionsRole>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['String']>;
  gender?: Maybe<Enum_Userspermissionsuser_Gender>;
  zip_code?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  house_number?: Maybe<Scalars['String']>;
  house_reference?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Maybe<Order>>>;
};

export type UsersPermissionsUserOrdersArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};

export type UsersPermissionsUserAggregator = {
  __typename?: 'UsersPermissionsUserAggregator';
  count?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type UsersPermissionsUserConnection = {
  __typename?: 'UsersPermissionsUserConnection';
  values?: Maybe<Array<Maybe<UsersPermissionsUser>>>;
  groupBy?: Maybe<UsersPermissionsUserGroupBy>;
  aggregate?: Maybe<UsersPermissionsUserAggregator>;
};

export type UsersPermissionsUserConnectionBirth_Date = {
  __typename?: 'UsersPermissionsUserConnectionBirth_date';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionBlocked = {
  __typename?: 'UsersPermissionsUserConnectionBlocked';
  key?: Maybe<Scalars['Boolean']>;
  connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionCity = {
  __typename?: 'UsersPermissionsUserConnectionCity';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionConfirmed = {
  __typename?: 'UsersPermissionsUserConnectionConfirmed';
  key?: Maybe<Scalars['Boolean']>;
  connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionCreated_At = {
  __typename?: 'UsersPermissionsUserConnectionCreated_at';
  key?: Maybe<Scalars['DateTime']>;
  connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionEmail = {
  __typename?: 'UsersPermissionsUserConnectionEmail';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionGender = {
  __typename?: 'UsersPermissionsUserConnectionGender';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionHouse_Number = {
  __typename?: 'UsersPermissionsUserConnectionHouse_number';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionHouse_Reference = {
  __typename?: 'UsersPermissionsUserConnectionHouse_reference';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionId = {
  __typename?: 'UsersPermissionsUserConnectionId';
  key?: Maybe<Scalars['ID']>;
  connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionName = {
  __typename?: 'UsersPermissionsUserConnectionName';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionNeighborhood = {
  __typename?: 'UsersPermissionsUserConnectionNeighborhood';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionPhone_Number = {
  __typename?: 'UsersPermissionsUserConnectionPhone_number';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionProvider = {
  __typename?: 'UsersPermissionsUserConnectionProvider';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionRole = {
  __typename?: 'UsersPermissionsUserConnectionRole';
  key?: Maybe<Scalars['ID']>;
  connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionStreet = {
  __typename?: 'UsersPermissionsUserConnectionStreet';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionSurname = {
  __typename?: 'UsersPermissionsUserConnectionSurname';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionUpdated_At = {
  __typename?: 'UsersPermissionsUserConnectionUpdated_at';
  key?: Maybe<Scalars['DateTime']>;
  connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionUsername = {
  __typename?: 'UsersPermissionsUserConnectionUsername';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionZip_Code = {
  __typename?: 'UsersPermissionsUserConnectionZip_code';
  key?: Maybe<Scalars['String']>;
  connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserGroupBy = {
  __typename?: 'UsersPermissionsUserGroupBy';
  id?: Maybe<Array<Maybe<UsersPermissionsUserConnectionId>>>;
  created_at?: Maybe<Array<Maybe<UsersPermissionsUserConnectionCreated_At>>>;
  updated_at?: Maybe<Array<Maybe<UsersPermissionsUserConnectionUpdated_At>>>;
  username?: Maybe<Array<Maybe<UsersPermissionsUserConnectionUsername>>>;
  email?: Maybe<Array<Maybe<UsersPermissionsUserConnectionEmail>>>;
  provider?: Maybe<Array<Maybe<UsersPermissionsUserConnectionProvider>>>;
  confirmed?: Maybe<Array<Maybe<UsersPermissionsUserConnectionConfirmed>>>;
  blocked?: Maybe<Array<Maybe<UsersPermissionsUserConnectionBlocked>>>;
  role?: Maybe<Array<Maybe<UsersPermissionsUserConnectionRole>>>;
  name?: Maybe<Array<Maybe<UsersPermissionsUserConnectionName>>>;
  surname?: Maybe<Array<Maybe<UsersPermissionsUserConnectionSurname>>>;
  phone_number?: Maybe<Array<Maybe<UsersPermissionsUserConnectionPhone_Number>>>;
  birth_date?: Maybe<Array<Maybe<UsersPermissionsUserConnectionBirth_Date>>>;
  gender?: Maybe<Array<Maybe<UsersPermissionsUserConnectionGender>>>;
  zip_code?: Maybe<Array<Maybe<UsersPermissionsUserConnectionZip_Code>>>;
  street?: Maybe<Array<Maybe<UsersPermissionsUserConnectionStreet>>>;
  house_number?: Maybe<Array<Maybe<UsersPermissionsUserConnectionHouse_Number>>>;
  house_reference?: Maybe<Array<Maybe<UsersPermissionsUserConnectionHouse_Reference>>>;
  neighborhood?: Maybe<Array<Maybe<UsersPermissionsUserConnectionNeighborhood>>>;
  city?: Maybe<Array<Maybe<UsersPermissionsUserConnectionCity>>>;
};

export type CreateCouponInput = {
  data?: Maybe<CouponInput>;
};

export type CreateCouponPayload = {
  __typename?: 'createCouponPayload';
  coupon?: Maybe<Coupon>;
};

export type CreateDeliveryManInput = {
  data?: Maybe<DeliveryManInput>;
};

export type CreateDeliveryManPayload = {
  __typename?: 'createDeliveryManPayload';
  deliveryMan?: Maybe<DeliveryMan>;
};

export type CreateOrderInput = {
  data?: Maybe<OrderInput>;
};

export type CreateOrderItemInput = {
  data?: Maybe<OrderItemInput>;
};

export type CreateOrderItemPayload = {
  __typename?: 'createOrderItemPayload';
  orderItem?: Maybe<OrderItem>;
};

export type CreateOrderPayload = {
  __typename?: 'createOrderPayload';
  order?: Maybe<Order>;
};

export type CreateProductCategoryInput = {
  data?: Maybe<ProductCategoryInput>;
};

export type CreateProductCategoryPayload = {
  __typename?: 'createProductCategoryPayload';
  productCategory?: Maybe<ProductCategory>;
};

export type CreateProductHistoryInput = {
  data?: Maybe<ProductHistoryInput>;
};

export type CreateProductHistoryPayload = {
  __typename?: 'createProductHistoryPayload';
  productHistory?: Maybe<ProductHistory>;
};

export type CreateProductInput = {
  data?: Maybe<ProductInput>;
};

export type CreateProductPayload = {
  __typename?: 'createProductPayload';
  product?: Maybe<Product>;
};

export type CreateRoleInput = {
  data?: Maybe<RoleInput>;
};

export type CreateRolePayload = {
  __typename?: 'createRolePayload';
  role?: Maybe<UsersPermissionsRole>;
};

export type CreateSupplierInput = {
  data?: Maybe<SupplierInput>;
};

export type CreateSupplierPayload = {
  __typename?: 'createSupplierPayload';
  supplier?: Maybe<Supplier>;
};

export type CreateUserInput = {
  data?: Maybe<UserInput>;
};

export type CreateUserPayload = {
  __typename?: 'createUserPayload';
  user?: Maybe<UsersPermissionsUser>;
};

export type DeleteCouponInput = {
  where?: Maybe<InputId>;
};

export type DeleteCouponPayload = {
  __typename?: 'deleteCouponPayload';
  coupon?: Maybe<Coupon>;
};

export type DeleteDeliveryFeePayload = {
  __typename?: 'deleteDeliveryFeePayload';
  deliveryFee?: Maybe<DeliveryFee>;
};

export type DeleteDeliveryManInput = {
  where?: Maybe<InputId>;
};

export type DeleteDeliveryManPayload = {
  __typename?: 'deleteDeliveryManPayload';
  deliveryMan?: Maybe<DeliveryMan>;
};

export type DeleteFileInput = {
  where?: Maybe<InputId>;
};

export type DeleteFilePayload = {
  __typename?: 'deleteFilePayload';
  file?: Maybe<UploadFile>;
};

export type DeleteOrderInput = {
  where?: Maybe<InputId>;
};

export type DeleteOrderItemInput = {
  where?: Maybe<InputId>;
};

export type DeleteOrderItemPayload = {
  __typename?: 'deleteOrderItemPayload';
  orderItem?: Maybe<OrderItem>;
};

export type DeleteOrderPayload = {
  __typename?: 'deleteOrderPayload';
  order?: Maybe<Order>;
};

export type DeleteProductCategoryInput = {
  where?: Maybe<InputId>;
};

export type DeleteProductCategoryPayload = {
  __typename?: 'deleteProductCategoryPayload';
  productCategory?: Maybe<ProductCategory>;
};

export type DeleteProductHistoryInput = {
  where?: Maybe<InputId>;
};

export type DeleteProductHistoryPayload = {
  __typename?: 'deleteProductHistoryPayload';
  productHistory?: Maybe<ProductHistory>;
};

export type DeleteProductInput = {
  where?: Maybe<InputId>;
};

export type DeleteProductPayload = {
  __typename?: 'deleteProductPayload';
  product?: Maybe<Product>;
};

export type DeleteRoleInput = {
  where?: Maybe<InputId>;
};

export type DeleteRolePayload = {
  __typename?: 'deleteRolePayload';
  role?: Maybe<UsersPermissionsRole>;
};

export type DeleteSupplierInput = {
  where?: Maybe<InputId>;
};

export type DeleteSupplierPayload = {
  __typename?: 'deleteSupplierPayload';
  supplier?: Maybe<Supplier>;
};

export type DeleteTemplatePayload = {
  __typename?: 'deleteTemplatePayload';
  template?: Maybe<Template>;
};

export type DeleteUserInput = {
  where?: Maybe<InputId>;
};

export type DeleteUserPayload = {
  __typename?: 'deleteUserPayload';
  user?: Maybe<UsersPermissionsUser>;
};

export type EditComponentDeliveryFeeFeeInput = {
  id?: Maybe<Scalars['ID']>;
  distance?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  order_subtotal_to_free_delivery?: Maybe<Scalars['Float']>;
};

export type EditCouponInput = {
  type?: Maybe<Enum_Coupon_Type>;
  name?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type EditDeliveryFeeInput = {
  origin?: Maybe<Scalars['String']>;
  delivery_fees?: Maybe<Array<Maybe<EditComponentDeliveryFeeFeeInput>>>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type EditDeliveryManInput = {
  phone_number?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type EditFileInput = {
  name?: Maybe<Scalars['String']>;
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  formats?: Maybe<Scalars['JSON']>;
  hash?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  mime?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  provider_metadata?: Maybe<Scalars['JSON']>;
  related?: Maybe<Array<Maybe<Scalars['ID']>>>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type EditOrderInput = {
  coupon?: Maybe<Scalars['ID']>;
  sub_total?: Maybe<Scalars['Float']>;
  coupon_discount?: Maybe<Scalars['Float']>;
  delivery_fee?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['String']>;
  order_items?: Maybe<Array<Maybe<Scalars['ID']>>>;
  order_status?: Maybe<Enum_Order_Order_Status>;
  order_payment_method?: Maybe<Enum_Order_Order_Payment_Method>;
  user?: Maybe<Scalars['ID']>;
  take_away?: Maybe<Scalars['Boolean']>;
  delivery_man?: Maybe<Scalars['ID']>;
  cancellation_reason?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type EditOrderItemInput = {
  order?: Maybe<Scalars['ID']>;
  quantity?: Maybe<Scalars['Float']>;
  product?: Maybe<Scalars['ID']>;
  unit_type?: Maybe<Enum_Orderitem_Unit_Type>;
  weight_purchase_price?: Maybe<Scalars['Float']>;
  unit_purchase_price?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
  discount_enabled?: Maybe<Scalars['Boolean']>;
  details?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type EditProductCategoryInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  view_order?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type EditProductHistoryInput = {
  product?: Maybe<Scalars['ID']>;
  purchase_price?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
  user?: Maybe<Scalars['ID']>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type EditProductInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  img?: Maybe<Scalars['ID']>;
  purchase_price?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
  enabled?: Maybe<Scalars['Boolean']>;
  discount_price?: Maybe<Scalars['Float']>;
  discount_enabled?: Maybe<Scalars['Boolean']>;
  product_categories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  suppliers?: Maybe<Array<Maybe<Scalars['ID']>>>;
  unit_purchase_price?: Maybe<Scalars['Float']>;
  unit_sale_price?: Maybe<Scalars['Float']>;
  unit_weight?: Maybe<Scalars['Float']>;
  unit_multiplus?: Maybe<Scalars['Int']>;
  unit_discount_price?: Maybe<Scalars['Float']>;
  sale_by_weight_enabled?: Maybe<Scalars['Boolean']>;
  sale_by_unit_enabled?: Maybe<Scalars['Boolean']>;
  contact_us_enabled?: Maybe<Scalars['Boolean']>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type EditRoleInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  users?: Maybe<Array<Maybe<Scalars['ID']>>>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type EditSupplierInput = {
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type EditTemplateInput = {
  banner?: Maybe<Scalars['ID']>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type EditUserInput = {
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  resetPasswordToken?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  blocked?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['String']>;
  gender?: Maybe<Enum_Userspermissionsuser_Gender>;
  zip_code?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  house_number?: Maybe<Scalars['String']>;
  house_reference?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Maybe<Scalars['ID']>>>;
  confirmationToken?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type UpdateCouponInput = {
  where?: Maybe<InputId>;
  data?: Maybe<EditCouponInput>;
};

export type UpdateCouponPayload = {
  __typename?: 'updateCouponPayload';
  coupon?: Maybe<Coupon>;
};

export type UpdateDeliveryFeeInput = {
  data?: Maybe<EditDeliveryFeeInput>;
};

export type UpdateDeliveryFeePayload = {
  __typename?: 'updateDeliveryFeePayload';
  deliveryFee?: Maybe<DeliveryFee>;
};

export type UpdateDeliveryManInput = {
  where?: Maybe<InputId>;
  data?: Maybe<EditDeliveryManInput>;
};

export type UpdateDeliveryManPayload = {
  __typename?: 'updateDeliveryManPayload';
  deliveryMan?: Maybe<DeliveryMan>;
};

export type UpdateOrderInput = {
  where?: Maybe<InputId>;
  data?: Maybe<EditOrderInput>;
};

export type UpdateOrderItemInput = {
  where?: Maybe<InputId>;
  data?: Maybe<EditOrderItemInput>;
};

export type UpdateOrderItemPayload = {
  __typename?: 'updateOrderItemPayload';
  orderItem?: Maybe<OrderItem>;
};

export type UpdateOrderPayload = {
  __typename?: 'updateOrderPayload';
  order?: Maybe<Order>;
};

export type UpdateProductCategoryInput = {
  where?: Maybe<InputId>;
  data?: Maybe<EditProductCategoryInput>;
};

export type UpdateProductCategoryPayload = {
  __typename?: 'updateProductCategoryPayload';
  productCategory?: Maybe<ProductCategory>;
};

export type UpdateProductHistoryInput = {
  where?: Maybe<InputId>;
  data?: Maybe<EditProductHistoryInput>;
};

export type UpdateProductHistoryPayload = {
  __typename?: 'updateProductHistoryPayload';
  productHistory?: Maybe<ProductHistory>;
};

export type UpdateProductInput = {
  where?: Maybe<InputId>;
  data?: Maybe<EditProductInput>;
};

export type UpdateProductPayload = {
  __typename?: 'updateProductPayload';
  product?: Maybe<Product>;
};

export type UpdateRoleInput = {
  where?: Maybe<InputId>;
  data?: Maybe<EditRoleInput>;
};

export type UpdateRolePayload = {
  __typename?: 'updateRolePayload';
  role?: Maybe<UsersPermissionsRole>;
};

export type UpdateSupplierInput = {
  where?: Maybe<InputId>;
  data?: Maybe<EditSupplierInput>;
};

export type UpdateSupplierPayload = {
  __typename?: 'updateSupplierPayload';
  supplier?: Maybe<Supplier>;
};

export type UpdateTemplateInput = {
  data?: Maybe<EditTemplateInput>;
};

export type UpdateTemplatePayload = {
  __typename?: 'updateTemplatePayload';
  template?: Maybe<Template>;
};

export type UpdateUserInput = {
  where?: Maybe<InputId>;
  data?: Maybe<EditUserInput>;
};

export type UpdateUserPayload = {
  __typename?: 'updateUserPayload';
  user?: Maybe<UsersPermissionsUser>;
};

export type GetCouponQueryVariables = Exact<{
  name: Scalars['String'];
}>;

export type GetCouponQuery = { __typename?: 'Query' } & {
  coupons?: Maybe<
    Array<Maybe<{ __typename?: 'Coupon' } & Pick<Coupon, 'id' | 'type' | 'name' | 'discount'>>>
  >;
};

export type GetDashboardOrdersQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;

export type GetDashboardOrdersQuery = { __typename?: 'Query' } & {
  orders?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Order' } & Pick<
          Order,
          | 'id'
          | 'order_payment_method'
          | 'order_status'
          | 'coupon_discount'
          | 'total'
          | 'created_at'
        > & {
            user?: Maybe<
              { __typename?: 'UsersPermissionsUser' } & Pick<
                UsersPermissionsUser,
                'name' | 'surname' | 'phone_number'
              >
            >;
          }
      >
    >
  >;
};

export type CalculateDeliveryFeeQueryVariables = Exact<{
  subtotal: Scalars['String'];
  couponType?: Maybe<Enum_Coupon_Type>;
}>;

export type CalculateDeliveryFeeQuery = { __typename?: 'Query' } & {
  calculate?: Maybe<
    { __typename?: 'ComponentDeliveryFeeFee' } & Pick<
      ComponentDeliveryFeeFee,
      'id' | 'distance' | 'fee'
    >
  >;
};

export type GetMyOrdersQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyOrdersQuery = { __typename?: 'Query' } & {
  myOrders?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Order' } & Pick<
          Order,
          | 'id'
          | 'order_payment_method'
          | 'order_status'
          | 'details'
          | 'sub_total'
          | 'coupon_discount'
          | 'delivery_fee'
          | 'total'
          | 'take_away'
          | 'created_at'
        > & {
            user?: Maybe<
              { __typename?: 'UsersPermissionsUser' } & Pick<
                UsersPermissionsUser,
                | 'name'
                | 'surname'
                | 'phone_number'
                | 'street'
                | 'neighborhood'
                | 'city'
                | 'house_number'
                | 'house_reference'
              >
            >;
            order_items?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'OrderItem' } & Pick<
                    OrderItem,
                    'id' | 'quantity' | 'details' | 'sale_price' | 'unit_type' | 'discount_enabled'
                  > & {
                      product?: Maybe<
                        { __typename?: 'Product' } & Pick<
                          Product,
                          'id' | 'name' | 'description' | 'sale_price'
                        >
                      >;
                    }
                >
              >
            >;
          }
      >
    >
  >;
};

export type GetOrdersQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  orderStatus?: Maybe<Array<Enum_Order_Order_Status> | Enum_Order_Order_Status>;
}>;

export type GetOrdersQuery = { __typename?: 'Query' } & {
  orders?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Order' } & Pick<
          Order,
          | 'id'
          | 'order_payment_method'
          | 'order_status'
          | 'details'
          | 'sub_total'
          | 'coupon_discount'
          | 'delivery_fee'
          | 'total'
          | 'take_away'
          | 'created_at'
        > & {
            user?: Maybe<
              { __typename?: 'UsersPermissionsUser' } & Pick<
                UsersPermissionsUser,
                | 'name'
                | 'surname'
                | 'phone_number'
                | 'street'
                | 'neighborhood'
                | 'city'
                | 'house_number'
                | 'house_reference'
              >
            >;
            order_items?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'OrderItem' } & Pick<
                    OrderItem,
                    | 'id'
                    | 'quantity'
                    | 'details'
                    | 'sale_price'
                    | 'unit_type'
                    | 'weight_purchase_price'
                    | 'unit_purchase_price'
                    | 'discount_enabled'
                  > & {
                      product?: Maybe<
                        { __typename?: 'Product' } & Pick<
                          Product,
                          'id' | 'name' | 'description' | 'purchase_price' | 'sale_price'
                        >
                      >;
                    }
                >
              >
            >;
          }
      >
    >
  >;
  deliveryMen?: Maybe<
    Array<Maybe<{ __typename?: 'DeliveryMan' } & Pick<DeliveryMan, 'id' | 'name'>>>
  >;
};

export type ProductCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type ProductCategoriesQuery = { __typename?: 'Query' } & {
  productCategories?: Maybe<
    Array<Maybe<{ __typename?: 'ProductCategory' } & Pick<ProductCategory, 'id' | 'name' | 'slug'>>>
  >;
};

export type ProductFieldsFragment = { __typename?: 'Product' } & Pick<
  Product,
  | 'id'
  | 'name'
  | 'description'
  | 'sale_price'
  | 'discount_enabled'
  | 'discount_price'
  | 'unit_discount_price'
  | 'unit_sale_price'
  | 'unit_weight'
  | 'unit_multiplus'
  | 'sale_by_weight_enabled'
  | 'sale_by_unit_enabled'
  | 'contact_us_enabled'
> & {
    img?: Maybe<{ __typename?: 'UploadFile' } & Pick<UploadFile, 'id' | 'name' | 'url'>>;
    product_categories?: Maybe<
      Array<Maybe<{ __typename?: 'ProductCategory' } & Pick<ProductCategory, 'id' | 'name'>>>
    >;
  };

export type ProductsQueryVariables = Exact<{
  productsId?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  productCategory?: Maybe<Scalars['String']>;
  offersOnly?: Maybe<Scalars['Boolean']>;
  searchQuery?: Maybe<Scalars['String']>;
}>;

export type ProductsQuery = { __typename?: 'Query' } & {
  products?: Maybe<Array<Maybe<{ __typename?: 'Product' } & ProductFieldsFragment>>>;
};

export type TemplateBannerQueryVariables = Exact<{ [key: string]: never }>;

export type TemplateBannerQuery = { __typename?: 'Query' } & {
  template?: Maybe<
    { __typename?: 'Template' } & {
      banner?: Maybe<{ __typename?: 'UploadFile' } & Pick<UploadFile, 'url'>>;
    }
  >;
};

export type GetUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersQuery = { __typename?: 'Query' } & {
  users?: Maybe<
    Array<
      Maybe<
        { __typename?: 'UsersPermissionsUser' } & Pick<
          UsersPermissionsUser,
          'id' | 'name' | 'surname' | 'phone_number'
        > & { orders?: Maybe<Array<Maybe<{ __typename?: 'Order' } & Pick<Order, 'id' | 'total'>>>> }
      >
    >
  >;
};

export const ProductFieldsFragmentDoc = gql`
  fragment ProductFields on Product {
    id
    name
    description
    sale_price
    discount_enabled
    discount_price
    unit_discount_price
    unit_sale_price
    unit_weight
    unit_multiplus
    unit_discount_price
    sale_by_weight_enabled
    sale_by_unit_enabled
    contact_us_enabled
    img {
      id
      name
      url
    }
    product_categories {
      id
      name
    }
  }
`;
export const GetCouponDocument = gql`
  query getCoupon($name: String!) {
    coupons(where: { name: $name, enabled: true }) {
      id
      type
      name
      discount
    }
  }
`;

/**
 * __useGetCouponQuery__
 *
 * To run a query within a React component, call `useGetCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetCouponQuery(
  baseOptions: Apollo.QueryHookOptions<GetCouponQuery, GetCouponQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCouponQuery, GetCouponQueryVariables>(GetCouponDocument, options);
}
export function useGetCouponLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCouponQuery, GetCouponQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCouponQuery, GetCouponQueryVariables>(GetCouponDocument, options);
}
export type GetCouponQueryHookResult = ReturnType<typeof useGetCouponQuery>;
export type GetCouponLazyQueryHookResult = ReturnType<typeof useGetCouponLazyQuery>;
export type GetCouponQueryResult = Apollo.QueryResult<GetCouponQuery, GetCouponQueryVariables>;
export const GetDashboardOrdersDocument = gql`
  query getDashboardOrders($startDate: String!, $endDate: String!) {
    orders(
      limit: 1000
      sort: "id:ASC"
      where: { created_at_gte: $startDate, created_at_lte: $endDate }
    ) {
      id
      order_payment_method
      order_status
      coupon_discount
      total
      created_at
      user {
        name
        surname
        phone_number
      }
    }
  }
`;

/**
 * __useGetDashboardOrdersQuery__
 *
 * To run a query within a React component, call `useGetDashboardOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardOrdersQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetDashboardOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<GetDashboardOrdersQuery, GetDashboardOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDashboardOrdersQuery, GetDashboardOrdersQueryVariables>(
    GetDashboardOrdersDocument,
    options
  );
}
export function useGetDashboardOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardOrdersQuery,
    GetDashboardOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDashboardOrdersQuery, GetDashboardOrdersQueryVariables>(
    GetDashboardOrdersDocument,
    options
  );
}
export type GetDashboardOrdersQueryHookResult = ReturnType<typeof useGetDashboardOrdersQuery>;
export type GetDashboardOrdersLazyQueryHookResult = ReturnType<
  typeof useGetDashboardOrdersLazyQuery
>;
export type GetDashboardOrdersQueryResult = Apollo.QueryResult<
  GetDashboardOrdersQuery,
  GetDashboardOrdersQueryVariables
>;
export const CalculateDeliveryFeeDocument = gql`
  query calculateDeliveryFee($subtotal: String!, $couponType: ENUM_COUPON_TYPE) {
    calculate(subtotal: $subtotal, couponType: $couponType) {
      id
      distance
      fee
    }
  }
`;

/**
 * __useCalculateDeliveryFeeQuery__
 *
 * To run a query within a React component, call `useCalculateDeliveryFeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateDeliveryFeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateDeliveryFeeQuery({
 *   variables: {
 *      subtotal: // value for 'subtotal'
 *      couponType: // value for 'couponType'
 *   },
 * });
 */
export function useCalculateDeliveryFeeQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalculateDeliveryFeeQuery,
    CalculateDeliveryFeeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CalculateDeliveryFeeQuery, CalculateDeliveryFeeQueryVariables>(
    CalculateDeliveryFeeDocument,
    options
  );
}
export function useCalculateDeliveryFeeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalculateDeliveryFeeQuery,
    CalculateDeliveryFeeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CalculateDeliveryFeeQuery, CalculateDeliveryFeeQueryVariables>(
    CalculateDeliveryFeeDocument,
    options
  );
}
export type CalculateDeliveryFeeQueryHookResult = ReturnType<typeof useCalculateDeliveryFeeQuery>;
export type CalculateDeliveryFeeLazyQueryHookResult = ReturnType<
  typeof useCalculateDeliveryFeeLazyQuery
>;
export type CalculateDeliveryFeeQueryResult = Apollo.QueryResult<
  CalculateDeliveryFeeQuery,
  CalculateDeliveryFeeQueryVariables
>;
export const GetMyOrdersDocument = gql`
  query getMyOrders {
    myOrders {
      id
      order_payment_method
      order_status
      details
      sub_total
      coupon_discount
      delivery_fee
      total
      take_away
      created_at
      user {
        name
        surname
        phone_number
        street
        neighborhood
        city
        house_number
        house_reference
      }
      order_items {
        id
        quantity
        details
        sale_price
        unit_type
        discount_enabled
        product {
          id
          name
          description
          sale_price
        }
      }
    }
  }
`;

/**
 * __useGetMyOrdersQuery__
 *
 * To run a query within a React component, call `useGetMyOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyOrdersQuery, GetMyOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyOrdersQuery, GetMyOrdersQueryVariables>(GetMyOrdersDocument, options);
}
export function useGetMyOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyOrdersQuery, GetMyOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyOrdersQuery, GetMyOrdersQueryVariables>(
    GetMyOrdersDocument,
    options
  );
}
export type GetMyOrdersQueryHookResult = ReturnType<typeof useGetMyOrdersQuery>;
export type GetMyOrdersLazyQueryHookResult = ReturnType<typeof useGetMyOrdersLazyQuery>;
export type GetMyOrdersQueryResult = Apollo.QueryResult<
  GetMyOrdersQuery,
  GetMyOrdersQueryVariables
>;
export const GetOrdersDocument = gql`
  query getOrders(
    $startDate: String!
    $endDate: String!
    $orderStatus: [ENUM_ORDER_ORDER_STATUS!]
  ) {
    orders(
      limit: 500
      sort: "id:DESC"
      where: { created_at_gte: $startDate, created_at_lte: $endDate, order_status: $orderStatus }
    ) {
      id
      order_payment_method
      order_status
      details
      sub_total
      coupon_discount
      delivery_fee
      total
      take_away
      created_at
      user {
        name
        surname
        phone_number
        street
        neighborhood
        city
        house_number
        house_reference
      }
      order_items {
        id
        quantity
        details
        sale_price
        unit_type
        weight_purchase_price
        unit_purchase_price
        discount_enabled
        product {
          id
          name
          description
          purchase_price
          sale_price
        }
      }
    }
    deliveryMen {
      id
      name
    }
  }
`;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      orderStatus: // value for 'orderStatus'
 *   },
 * });
 */
export function useGetOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
}
export function useGetOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
}
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersQueryResult = Apollo.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>;
export const ProductCategoriesDocument = gql`
  query ProductCategories {
    productCategories(sort: "view_order:ASC") {
      id
      name
      slug
    }
  }
`;

/**
 * __useProductCategoriesQuery__
 *
 * To run a query within a React component, call `useProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<ProductCategoriesQuery, ProductCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductCategoriesQuery, ProductCategoriesQueryVariables>(
    ProductCategoriesDocument,
    options
  );
}
export function useProductCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductCategoriesQuery, ProductCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductCategoriesQuery, ProductCategoriesQueryVariables>(
    ProductCategoriesDocument,
    options
  );
}
export type ProductCategoriesQueryHookResult = ReturnType<typeof useProductCategoriesQuery>;
export type ProductCategoriesLazyQueryHookResult = ReturnType<typeof useProductCategoriesLazyQuery>;
export type ProductCategoriesQueryResult = Apollo.QueryResult<
  ProductCategoriesQuery,
  ProductCategoriesQueryVariables
>;
export const ProductsDocument = gql`
  query Products(
    $productsId: [ID!]
    $productCategory: String
    $offersOnly: Boolean
    $searchQuery: String
  ) {
    products(
      sort: "name:ASC"
      where: {
        id: $productsId
        product_categories: { slug: $productCategory }
        discount_enabled: $offersOnly
        enabled: true
        name_contains: $searchQuery
      }
    ) {
      ...ProductFields
    }
  }
  ${ProductFieldsFragmentDoc}
`;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      productsId: // value for 'productsId'
 *      productCategory: // value for 'productCategory'
 *      offersOnly: // value for 'offersOnly'
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
}
export function useProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
}
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const TemplateBannerDocument = gql`
  query TemplateBanner {
    template {
      banner {
        url
      }
    }
  }
`;

/**
 * __useTemplateBannerQuery__
 *
 * To run a query within a React component, call `useTemplateBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateBannerQuery({
 *   variables: {
 *   },
 * });
 */
export function useTemplateBannerQuery(
  baseOptions?: Apollo.QueryHookOptions<TemplateBannerQuery, TemplateBannerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TemplateBannerQuery, TemplateBannerQueryVariables>(
    TemplateBannerDocument,
    options
  );
}
export function useTemplateBannerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TemplateBannerQuery, TemplateBannerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TemplateBannerQuery, TemplateBannerQueryVariables>(
    TemplateBannerDocument,
    options
  );
}
export type TemplateBannerQueryHookResult = ReturnType<typeof useTemplateBannerQuery>;
export type TemplateBannerLazyQueryHookResult = ReturnType<typeof useTemplateBannerLazyQuery>;
export type TemplateBannerQueryResult = Apollo.QueryResult<
  TemplateBannerQuery,
  TemplateBannerQueryVariables
>;
export const GetUsersDocument = gql`
  query getUsers {
    users(sort: "id:ASC") {
      id
      name
      surname
      phone_number
      orders {
        id
        total
      }
    }
  }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
