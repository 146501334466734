import React, { FC, useEffect, useState, createContext } from 'react';

import * as Sentry from '@sentry/browser';

import { UserInput } from '@graphql/generated';
import { getUser } from '@services/authentication';

export interface IUserContext {
  user: UserInput;
  setUser: (user: UserInput | null) => void;
}

export const USER_CONTEXT_INITIAL_VALUES = {
  user: undefined,
  setUser: undefined
};

export const UserContext = createContext<IUserContext>(USER_CONTEXT_INITIAL_VALUES);

const UserContextProvider: FC = ({ children }) => {
  const [state, setState] = useState<IUserContext>(USER_CONTEXT_INITIAL_VALUES);

  useEffect(() => {
    getUser().then((res) => setUser(res?.data));
  }, []);

  const setUser = (user: UserInput | null): void => {
    const sentryUser = user ? { email: user.email } : null;
    Sentry.setUser(sentryUser);

    setState((oldState) => ({ ...oldState, user }));
  };

  const userContext: IUserContext = {
    user: state.user,
    setUser: setUser
  };

  return <UserContext.Provider value={userContext}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
