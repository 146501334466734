import React, { FC, useContext } from 'react';

import { Box, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Image from 'next/image';

import analytics from '@config/analytics';
import { currency, getImageURL } from '@config/utils';
import { CartContext, ICartItem } from '@context/CartContext';
import { EProductOperation, getPrice } from '@helpers/products';

import ItemCounter from '../ItemCounter';
import ProductDetailsInput from './ProductDetailsInput';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexGrow: 1
  },
  productContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: '0 0.5rem 1rem'
  },
  productItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    flexGrow: 1,
    marginBottom: '1rem'
  },
  img: {
    alignSelf: 'center'
  },
  detailsContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  quantityContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    flex: '0 0 100px'
  },
  itemTotal: {
    fontWeight: 'bold'
  },
  deleteButton: {
    alignSelf: 'flex-start',
    flex: '0 0 30px'
  }
});

interface IProps {
  item: ICartItem;
}

const CartProduct: FC<IProps> = (props) => {
  const classes = useStyles();
  const cartContext = useContext(CartContext);

  const { product, unitType, quantity, details } = props.item;
  const { name, description, img } = props.item.product;

  function onCounterChange(qtd: number, operation: EProductOperation) {
    const isAdding = operation === EProductOperation.ADD;

    if (isAdding) {
      cartContext.addItem({ product, unitType, quantity: qtd });
    } else {
      cartContext.removeItem({ product, unitType, quantity: qtd });
    }

    let eventAction = isAdding ? 'Incrementar Item' : 'Decrementar Item';
    if (!qtd) {
      eventAction = 'Zerar Item';
    }
    analytics.event(eventAction, {
      event_category: 'Menu Carrinho',
      event_label: unitType,
      value: Number(product.id)
    });
  }

  function onUpdateDetails(details) {
    cartContext.addItem({ product, unitType, quantity, details });
  }

  function onDelete() {
    cartContext.removeItem({ product, unitType, quantity: 0 });

    analytics.event('Remover Item', {
      event_category: 'Menu Carrinho',
      event_label: 'Botão Remover',
      value: Number(product.id)
    });
  }

  return (
    <Box className={classes.root}>
      <Image
        className={classes.img}
        title={name}
        alt={name}
        src={getImageURL(img)}
        width={80}
        height={80}
        unoptimized
        objectFit="contain"
      />

      <Box className={classes.productContainer}>
        <Box className={classes.productItem}>
          <Box className={classes.detailsContainer}>
            <Typography variant="h5" component="h5">
              {name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {description}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {currency(getPrice(product, unitType))}
            </Typography>
          </Box>

          <Box className={classes.quantityContainer}>
            <Typography variant="body1" color="textPrimary" className={classes.itemTotal}>
              {currency(getPrice(product, unitType) * quantity)}
            </Typography>
            <ItemCounter
              product={product}
              value={quantity}
              onChange={onCounterChange}
              unitType={unitType}
            />
          </Box>
        </Box>

        <ProductDetailsInput details={details} onSave={onUpdateDetails} />
      </Box>

      <IconButton onClick={onDelete} size="small" className={classes.deleteButton}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default CartProduct;
