import React, { FC } from 'react';

import {
  ApolloClient,
  ApolloProvider as ApolloClientProvider,
  createHttpLink,
  InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { COOKIES, getCookie } from '@services/cookies';

import { API_URL } from './constants';

/**
 * FUTURE:
 * stackoverflow.com/questions/50965347/how-to-execute-an-async-fetch-request-and-then-retry-last-failed-request/51321068#51321068
 */
const httpLink = createHttpLink({
  uri: `${API_URL}/graphql` as string
});

const authLink = setContext((operation, prevContext) => {
  const jwt = getCookie(COOKIES.Jwt);

  return {
    headers: {
      ...prevContext.headers,
      authorization: jwt ? `Bearer ${jwt}` : ''
    }
  };
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

const ApolloProvider: FC = ({ children }) => {
  return <ApolloClientProvider client={apolloClient}>{children}</ApolloClientProvider>;
};

export default ApolloProvider;
