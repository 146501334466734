import { IForgotPasswordForm } from 'pages/forgot-password/interfaces';

import { api, ApiResponse } from '@config/axios';
import { Enum_Userspermissionsuser_Gender, UserInput } from '@graphql/generated';

import { IRegisterForm } from '../../pages/register/interfaces';
import { COOKIES, setCookie } from '../cookies';

export const registerUser = (
  user: IRegisterForm
): ApiResponse<{ jwt: string; user: UserInput }> => {
  const data: UserInput = {
    ...user,
    username: user.email.trim(),
    gender: user.gender as Enum_Userspermissionsuser_Gender
    // birth_date: toDate(user.birth_date)
  };

  return api.post(`/auth/local/register`, data).then((res) => {
    //set token response from Strapi for server validation
    setCookie(COOKIES.Jwt, res.data.jwt);

    //resolve the promise to set loading to false in SignUp form
    return res;
  });
};

export const updateUser = (data: IRegisterForm): ApiResponse<UserInput> => {
  return api.put(`/users/update/me`, data);
};

export const forgotPassword = (data: IForgotPasswordForm): ApiResponse<unknown> => {
  return api.post(`/auth/forgot-password`, data);
};

export const resetPassword = (data: {
  code: string;
  password: string;
  passwordConfirmation: string;
}): ApiResponse<unknown> => {
  return api.post(`/auth/reset-password`, data);
};
