export const PATHS = {
  ROOT: '/',

  CHECKOUT: '/checkout',
  THANK_YOU: '/thank-you',

  USER_ORDERS: '/user/orders',
  USER_DATA: '/user/data',

  ADMIN_ORDERS: '/admin/orders',
  ADMIN_USERS: '/admin/users',
  ADMIN_DASHBOARD: '/admin/dashboard'
};

export const USER_PATHS = [
  { value: PATHS.USER_ORDERS, label: 'Meus Pedidos' },
  { value: PATHS.USER_DATA, label: 'Meus Dados' }
];

export const ADMIN_PATHS = [
  { value: PATHS.ADMIN_ORDERS, label: 'Pedidos' },
  { value: PATHS.ADMIN_USERS, label: 'Usuários' },
  { value: PATHS.ADMIN_DASHBOARD, label: 'Dashboard' }
];
