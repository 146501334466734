import React, { forwardRef, ForwardRefRenderFunction } from 'react';

import { MenuItem } from '@material-ui/core';
import Link from 'next/link';

interface IProps {
  path: { value: string; label: string };
  onClick: () => void;
}
const MenuItemLink: ForwardRefRenderFunction<HTMLLIElement, IProps> = (props, ref) => {
  const { path, onClick } = props;

  return (
    <li ref={ref}>
      <Link href={path.value} passHref>
        <MenuItem onClick={onClick} component="a">
          {path.label}
        </MenuItem>
      </Link>
    </li>
  );
};

export default forwardRef<HTMLLIElement, IProps>(MenuItemLink);
