import { Box, Button, Drawer, IconButton, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Link from 'next/link';

import React, { FC, Fragment, KeyboardEvent, MouseEvent, useContext } from 'react';

import analytics from '@config/analytics';
import { PATHS } from '@config/paths';
import { currency } from '@config/utils';
import { CartContext } from '@context/CartContext';

import { UIContext } from '../../../context/UIContext';
import { UserContext } from '../../../context/UserContext';

import { getDeliveryDayMessage } from '@helpers/checkout';

import CartList from '../../shared/CartList';

import { drawerDirections, IDrawerState } from '..';

const useStyles = makeStyles<Theme>((theme) => ({
  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '33vw',
    minWidth: '420px',
    height: '100%',

    [theme.breakpoints.down('md')]: {
      width: '100vw',
      minWidth: 'auto',
      maxHeight: '75vh'
    }
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem 2rem',

    [theme.breakpoints.down('md')]: {
      padding: '1rem 1rem'
    }
  },
  closeDrawerButton: {
    // marginLeft: 'auto'
  },
  cartListContainer: {
    flexGrow: 1,
    overflowY: 'auto'
  },
  checkoutContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1rem 2rem',
    marginRight: '3px',
    borderTop: '1px solid #ddd',

    [theme.breakpoints.down('md')]: {
      padding: '1rem'
    }
  }
}));

interface IProps {
  toggleCartDrawer(event: KeyboardEvent | MouseEvent);
  cartDrawer: IDrawerState;
}

const CartDrawer: FC<IProps> = (props) => {
  const { toggleCartDrawer, cartDrawer } = props;

  const classes = useStyles();

  const userContext = useContext(UserContext);
  const cartContext = useContext(CartContext);
  const uiContext = useContext(UIContext);

  const minimumOrder = 0;
  const cartTotal = cartContext.getTotal();
  const items = cartContext.items;
  const itemLabel = items.length > 1 ? 'itens' : 'item';

  function handleClick(event: KeyboardEvent | MouseEvent) {
    if (!userContext.user) {
      event.preventDefault();
      return uiContext.login.toggleModal(true);
    }

    toggleCartDrawer(event);

    analytics.event('Escolher Forma de Pagamento', {
      event_category: 'Menu Carrinho'
    });
  }

  function handleClose(event: KeyboardEvent | MouseEvent) {
    toggleCartDrawer(event);

    analytics.event('Fechar', {
      event_category: 'Menu Carrinho',
      event_label: 'Botão Fechar'
    });
  }

  function shouldDisableButton() {
    return !items.length || !cartTotal || (!!minimumOrder && cartTotal < minimumOrder);
  }

  return (
    <>
      {drawerDirections.map((anchor) => (
        <Fragment key={anchor}>
          <Drawer anchor={anchor} open={cartDrawer[anchor]} onClose={toggleCartDrawer}>
            <Box className={classes.drawerContainer}>
              <Box className={classes.headerContainer}>
                <Typography variant="h5">
                  Seu Pedido {items.length !== 0 && `(${items.length} ${itemLabel})`}
                </Typography>

                <IconButton
                  className={classes.closeDrawerButton}
                  onClick={handleClose}
                  size="small">
                  <CloseIcon />
                </IconButton>
              </Box>

              <Box className={classes.cartListContainer}>
                <CartList items={items} />
              </Box>

              <Box className={classes.checkoutContainer}>
                <Box display="flex" justifyContent="space-between" mb="1rem">
                  <Typography variant="h5">Total</Typography>
                  <Typography variant="h5">{currency(cartTotal)}</Typography>
                </Box>
                {!!minimumOrder && (
                  <Typography variant="subtitle2" align="right">
                    Pedido mínimo: {currency(minimumOrder)}
                  </Typography>
                )}
                <Typography variant="subtitle2" align="right" gutterBottom>
                  {getDeliveryDayMessage()}
                </Typography>
                <Link href={PATHS.CHECKOUT} passHref>
                  <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    onClick={handleClick}
                    disabled={shouldDisableButton()}
                    component="a">
                    Escolher forma de pagamento
                  </Button>
                </Link>
              </Box>
            </Box>
          </Drawer>
        </Fragment>
      ))}
    </>
  );
};

export default CartDrawer;
