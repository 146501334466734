import React, { FC } from 'react';

import { Button as MuiButton, ButtonProps, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  loadingIcon: {
    position: 'absolute'
  },
  loadingText: {
    color: 'transparent'
  }
});

interface IProps extends ButtonProps {
  loading?: boolean;
}

const Button: FC<IProps> = (props) => {
  const { loading, ...rest } = props;
  const classes = useStyles();

  return (
    <MuiButton {...rest} disabled={props.disabled || loading}>
      <>
        {loading && <CircularProgress size={20} className={classes.loadingIcon} />}
        <span className={loading ? classes.loadingText : ''}>{props.children}</span>
      </>
    </MuiButton>
  );
};

export default Button;
