import React, { FC, KeyboardEvent, MouseEvent, useContext } from 'react';

import {
  AppBar,
  Badge,
  Box,
  Button,
  Divider,
  Hidden,
  IconButton,
  Theme,
  Toolbar,
  Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import analytics from '@config/analytics';
import { PATHS } from '@config/paths';
import { CartContext } from '@context/CartContext';
import { logout } from '@services/authentication';

import { UIContext } from '../../../context/UIContext';
import { UserContext } from '../../../context/UserContext';
import Search from '../Search';
import UserMenu from './UserMenu';

const useStyles = makeStyles<Theme>((theme) => ({
  logoContainer: {
    display: 'flex',

    [theme.breakpoints.down('md')]: {
      padding: 'inherit',
      position: 'absolute',
      width: '100%',
      justifyContent: 'center',
      left: 0
    }
  },
  menuButton: {
    zIndex: 1,
    display: 'none',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  rightActionsContainer: {
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    width: '100%'
  },
  toolbar: theme.mixins.toolbar
}));

interface IProps {
  toggleCartDrawer(event: KeyboardEvent | MouseEvent);
  toggleMenuDrawer(event: KeyboardEvent | MouseEvent);
}

const Header: FC<IProps> = (props) => {
  const classes = useStyles();
  const router = useRouter();

  const { user, setUser } = useContext(UserContext);
  const cartContext = useContext(CartContext);
  const uiContext = useContext(UIContext);

  const { toggleMenuDrawer, toggleCartDrawer } = props;

  function handleLogout() {
    logout();
    setUser(null);

    analytics.event('Logout', {
      event_category: 'Menu Perfil'
    });
  }

  function handleLogin() {
    uiContext.login.toggleModal(true);

    analytics.event('Login', {
      event_category: 'Menu Topo'
    });
  }

  function collectBackButtonMetrics() {
    analytics.event('Voltar', {
      event_category: 'Menu Topo'
    });
  }

  const isRootRoute = router.route === PATHS.ROOT;
  return (
    <>
      <AppBar position="fixed" color="default">
        <Toolbar>
          {isRootRoute ? (
            <Tooltip title="Menu">
              <IconButton edge="start" className={classes.menuButton} onClick={toggleMenuDrawer}>
                <MenuIcon />
              </IconButton>
            </Tooltip>
          ) : (
            // <Tooltip title="Voltar">
            <Link href={PATHS.ROOT} passHref>
              <IconButton
                edge="start"
                className={classes.menuButton}
                onClick={collectBackButtonMetrics}
                component="a">
                <ArrowBackIcon />
              </IconButton>
            </Link>
            // </Tooltip>
          )}

          <Box className={classes.logoContainer}>
            <Link href={PATHS.ROOT}>
              <a>
                <Image src="/images/logo-text.png" alt="logo" width={170} height={34} />
              </a>
            </Link>
          </Box>

          <Box className={classes.rightActionsContainer}>
            {isRootRoute && <Search />}

            <Tooltip title="Carrinho">
              <IconButton onClick={toggleCartDrawer}>
                <Badge badgeContent={cartContext.items.length} color="secondary">
                  <ShoppingCartIcon />
                </Badge>
              </IconButton>
            </Tooltip>

            <Hidden mdDown>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                className={classes.hiddenMdDown}
              />
            </Hidden>

            {user ? (
              <UserMenu user={user} logout={handleLogout} />
            ) : (
              <Hidden mdDown>
                <Button variant="contained" color="primary" onClick={handleLogin}>
                  Entrar
                </Button>
              </Hidden>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      <Box className={classes.toolbar} />
    </>
  );
};

export default Header;
