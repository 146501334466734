import { GOOGLE_ANALYTICS_PIXEL, NODE_ENV } from './constants';

const pageview = (url: string): void => {
  if (NODE_ENV === 'production' && !window.gtag) {
    throw Error('Gtag não carregou (async fail)...');
  }

  window.gtag?.('config', GOOGLE_ANALYTICS_PIXEL, {
    page_path: url
  });
};

const event = (
  action: string,
  eventParams: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams
): void => {
  window.gtag?.('event', action, eventParams);
};

const analytics = {
  pageview,
  event
};

export default analytics;
