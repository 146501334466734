import React, { FC } from 'react';

import { Box, Button, Theme, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { UserInput } from '@graphql/generated';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      padding: '1rem'
    },
    username: {
      padding: '4px 5px',
      maxWidth: '120px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    logoutButton: {
      marginLeft: 'auto'
    }
  })
);

interface IProps {
  user: UserInput;
  register: () => void;
  login: () => void;
  logout: () => void;
}

const UserDrawerMenu: FC<IProps> = (props) => {
  const classes = useStyles();

  if (props.user) {
    return (
      <Box className={classes.container}>
        {/* <Avatar>GT</Avatar> */}
        <Typography className={classes.username}>
          {props.user.name} {props.user.surname}
        </Typography>

        <Button
          variant="text"
          color="secondary"
          size="small"
          onClick={props.logout}
          className={classes.logoutButton}>
          Sair
        </Button>
      </Box>
    );
  }

  return (
    <Box className={classes.container} justifyContent="space-between">
      <Button size="small" color="primary" onClick={props.register}>
        Cadastrar
      </Button>

      <Button color="primary" variant="contained" onClick={props.login}>
        Entrar
      </Button>
    </Box>
  );
};

export default UserDrawerMenu;
