import * as Yup from 'yup';

import { IResetPasswordForm } from './interfaces';

export const RECOVERY_PASSWORD_INITIAL_VALUES: IResetPasswordForm = {
  code: '',
  password: '',
  passwordConfirmation: ''
};

export const RecoveryPasswordValidationSchema = Yup.object().shape<IResetPasswordForm>({
  code: Yup.string(),
  password: Yup.string().required('Campo obrigatório'),
  passwordConfirmation: Yup.string()
    .required('Campo obrigatório')
    .oneOf([Yup.ref('password'), null], 'As senhas devem ser idênticas')
});
