import React, { FC, useState, createContext, useEffect } from 'react';

import { useRouter } from 'next/router';

export type IUIContext = {
  search: {
    query: string;
    setQuery: (query: string) => void;

    isTyping: boolean;
    setTyping: (isTyping: boolean) => void;
  };
  login: {
    loginModalIsOpen: boolean;
    toggleModal: (isOpen: boolean) => void;
  };
  register: {
    registerModalIsOpen: boolean;
    toggleModal: (isOpen: boolean) => void;
  };
  forgotPassword: {
    forgotPasswordModalIsOpen: boolean;
    toggleModal: (isOpen: boolean) => void;
  };
  resetPassword: {
    resetPasswordModalIsOpen: boolean;
    toggleModal: (isOpen: boolean) => void;
  };
};

export const UI_CONTEXT_INITIAL_VALUES: IUIContext = {
  search: {
    query: undefined,
    setQuery: undefined,

    isTyping: false,
    setTyping: undefined
  },
  login: {
    loginModalIsOpen: false,
    toggleModal: undefined
  },
  register: {
    registerModalIsOpen: false,
    toggleModal: undefined
  },
  forgotPassword: {
    forgotPasswordModalIsOpen: false,
    toggleModal: undefined
  },
  resetPassword: {
    resetPasswordModalIsOpen: false,
    toggleModal: undefined
  }
};

export const UIContext = createContext<IUIContext>(UI_CONTEXT_INITIAL_VALUES);

const UIContextProvider: FC = ({ children }) => {
  const router = useRouter();

  const [query, setQuery] = useState<IUIContext['search']['query']>(
    UI_CONTEXT_INITIAL_VALUES.search.query
  );
  const [isTyping, setTyping] = useState<IUIContext['search']['isTyping']>(
    UI_CONTEXT_INITIAL_VALUES.search.isTyping
  );

  const [loginModalIsOpen, toggleLoginModal] = useState<IUIContext['login']['loginModalIsOpen']>(
    UI_CONTEXT_INITIAL_VALUES.login.loginModalIsOpen
  );
  const [registerModalIsOpen, toggleRegisterModal] = useState<
    IUIContext['register']['registerModalIsOpen']
  >(UI_CONTEXT_INITIAL_VALUES.register.registerModalIsOpen);

  const [forgotPasswordModalIsOpen, toggleForgotPasswordModal] = useState<
    IUIContext['forgotPassword']['forgotPasswordModalIsOpen']
  >(UI_CONTEXT_INITIAL_VALUES.register.registerModalIsOpen);

  const [resetPasswordModalIsOpen, toggleRecoveryPasswordModal] = useState<
    IUIContext['resetPassword']['resetPasswordModalIsOpen']
  >(UI_CONTEXT_INITIAL_VALUES.register.registerModalIsOpen);

  useEffect(() => {
    if (router.query.resetPasswordToken) {
      toggleRecoveryPasswordModal(true);
    }
  }, [router.query]);

  const uiContext: IUIContext = {
    search: {
      query,
      setQuery,

      isTyping,
      setTyping
    },
    login: {
      loginModalIsOpen,
      toggleModal: toggleLoginModal
    },
    register: {
      registerModalIsOpen,
      toggleModal: toggleRegisterModal
    },
    forgotPassword: {
      forgotPasswordModalIsOpen,
      toggleModal: toggleForgotPasswordModal
    },
    resetPassword: {
      resetPasswordModalIsOpen: resetPasswordModalIsOpen,
      toggleModal: toggleRecoveryPasswordModal
    }
  };

  return <UIContext.Provider value={uiContext}>{children}</UIContext.Provider>;
};

export default UIContextProvider;
