import { UNIT_WEIGHT } from '@config/constants';
import { Enum_Orderitem_Unit_Type, Product } from '@graphql/generated';

export enum EProductOperation {
  ADD = 'add',
  REMOVE = 'remove'
}

export function getUnitTypeLabel(unitType: Enum_Orderitem_Unit_Type): string {
  if (unitType === Enum_Orderitem_Unit_Type.Weight) {
    return 'kg';
  }

  if (unitType === Enum_Orderitem_Unit_Type.Unit) {
    return 'un';
  }
}

export function getPrice(product: Product, unitType: Enum_Orderitem_Unit_Type): number {
  if (unitType === Enum_Orderitem_Unit_Type.Weight) {
    const weightPrice = product.discount_enabled ? product.discount_price : product.sale_price;
    return weightPrice;
  }

  const unitPrice = getUnitPrice(product);
  return unitPrice;
}

export function getUnitPrice(product: Product, withDiscount = true): number {
  if (withDiscount && product.discount_enabled && product.unit_discount_price) {
    return product.unit_discount_price;
  }

  if (product.unit_sale_price) {
    return product.unit_sale_price;
  }

  if (product.sale_price && product.unit_weight && product.unit_multiplus) {
    return getUnitPriceFromMultiplus(product);
  }
}

function getUnitPriceFromMultiplus(product: Product): number {
  const realUnitPrice = product.sale_price * product.unit_weight;
  return realUnitPrice + (realUnitPrice * product.unit_multiplus) / 100;
}

export function getQuantity(product: Product, unitType: Enum_Orderitem_Unit_Type): number {
  const { unit_weight } = product;
  const unitIncrementor = 1;
  const weightIncrementor = 0.5;

  let incrementor = unitIncrementor;
  if (unitType === Enum_Orderitem_Unit_Type.Weight) {
    incrementor = UNIT_WEIGHT === 'FROM_SERVER' && unit_weight ? unit_weight : weightIncrementor;
  }

  return incrementor;
}
