import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export function getWhatsappMessageURL(message: string, number: string): string {
  const unmaskedPhone = '55' + number.replace(/\D+/g, '');
  const text = encodeURIComponent(message);

  return `https://wa.me/${unmaskedPhone}?text=${text}`;
}

export function sendWhatsappMessage(message?: string, number?: string): void {
  const url = getWhatsappMessageURL(message, number);

  window.open(url, '_blank', 'noopener noreferrer');
}

export function formatDate(date: Date, dateFormat = 'yyyy-MM-dd'): string {
  return format(date, dateFormat, { locale: ptBR });
}

export function getURLParam(search: string, param: string): string | null {
  // INFO: router.query.category first render is undefined =(
  const params = new URLSearchParams(search.split('?')?.[1]);
  return params.get(param);
}
